import { makeAutoObservable } from 'mobx'
import { RootStore } from 'stores'
import { handleError } from 'API/error'
import { getCMSHouseDefaultValue, upsertCMSHouseDefaultValue } from '../../API/cms/generalInformation'
import { IHouseDefaultValue } from '../../interfaces/houseDefaultValue'
class HouseDefaultValueStore {
  rootStore: RootStore
  cmsHouseDefaultValue: IHouseDefaultValue = {}
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  async fetchCMSHouseDefaultValue() {
    try {
      const houseDefaultValue: IHouseDefaultValue[] = await getCMSHouseDefaultValue()
      this.cmsHouseDefaultValue = houseDefaultValue[0]
    } catch (error) {
      handleError(error as Error, 'stores/cms/cmsHouseDefaultValueStore.ts', 'fetchCMSHouseDefaultValue')
    }
  }

  async updateHouseDefaultValue(houseDefaultValueData: IHouseDefaultValue): Promise<void> {
    await upsertCMSHouseDefaultValue(houseDefaultValueData)
  }
}

export default HouseDefaultValueStore
