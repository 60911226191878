import { makeAutoObservable } from 'mobx'
import { RootStore } from 'stores'
import { handleError } from 'API/error'
import { getFinancials, getFinancialsOverall } from 'API/ownerPortal/financial'
import { IPropertyFinancial } from 'components/pages/OwnerPortal/FinancialPage/enum'
import { IFinancialOverall } from 'interfaces/financial'

class FinancialStore {
  rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  public buildiumOwner: number = 0
  public houseList: IPropertyFinancial[] = []
  public checkedHouseList: IPropertyFinancial[] = []
  public totalCount: number = 0
  public financialOverall: IFinancialOverall = {} as IFinancialOverall

  async fetchFinancialList(
    skip: number,
    limit: number,
    selectedYear?: string,
    propertyIds: number[] = []
  ): Promise<void> {
    try {
      const { results: houseList, totalCount } = await getFinancials(skip, limit, selectedYear, propertyIds)
      this.houseList = houseList
      this.totalCount = totalCount
    } catch (error) {
      handleError(error as Error, 'stores/ownerPortal/ownerFinancialStore.ts', 'fetchFinancialList')
    }
  }

  async fetchPropertyList(): Promise<void> {
    try {
      const { results: houseList } = await getFinancials()
      this.checkedHouseList = houseList
    } catch (error) {
      handleError(error as Error, 'stores/ownerPortal/ownerFinancialStore.ts', 'fetchPropertyList')
    }
  }

  async getFinancialOverall(selectedDay: Date): Promise<void> {
    try {
      this.financialOverall = await getFinancialsOverall(selectedDay)
    } catch (error) {
      handleError(error as Error, 'stores/ownerPortal/ownerFinancialStore.ts', 'getFinancialOverall')
    }
  }
}

export default FinancialStore
