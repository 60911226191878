import { AxiosResponse } from 'axios'
import get from 'lodash/get'
import { AggregationPipeline } from 'types/mongo'
import { IFilter } from 'types/query'
import { api, auth } from 'API'
import { PLATFORM } from 'API/constants'
import { handleError } from 'API/error'
import { IBuildiumOwner } from 'interfaces/owner'

export async function getBuildiumOwners(filter: IFilter<IBuildiumOwner>): Promise<IBuildiumOwner[]> {
  try {
    const response = await api.get(`/staff/buildium-owners?filter=${JSON.stringify(filter)}`, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/cms/getBuildiumOwners.ts', 'getBuildiumOwners')
    return []
  }
}

export async function getBuildiumOwnerDetail(id: string, filter: IFilter<IBuildiumOwner>): Promise<IBuildiumOwner> {
  try {
    const response = await api.get(`/staff/buildium-owners/${id}?filter=${JSON.stringify(filter)}`, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/cms/getBuildiumOwners.ts', 'getBuildiumOwnerDetail')
    throw new Error(errorMessage)
  }
}

export async function countBuildiumOwners(where: Record<string, unknown> = {}): Promise<number> {
  try {
    const { data = 0 } = await api.get(`/staff/buildium-owners/count?where=${JSON.stringify(where)}`, {
      headers: auth(PLATFORM.CMS)
    })
    return Number(get(data, 'count', 0))
  } catch (error) {
    handleError(error as Error, 'API/cms/getBuildiumOwners.ts', 'countBuildiumOwners')
    return 0
  }
}

export async function getBuildiumOwnersAggregate(pipeline: AggregationPipeline): Promise<IBuildiumOwner[]> {
  try {
    const response: AxiosResponse = await api.post(
      `/staff/buildium-owners/aggregate`,
      { pipeline },
      {
        headers: auth(PLATFORM.CMS)
      }
    )
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/cms/owner.ts', 'getBuildiumOwnersAggregate')
    return []
  }
}

export async function createBuildiumOwner(ownerData: IBuildiumOwner): Promise<IBuildiumOwner> {
  try {
    const response = await api.post(`/staff/buildium-owners`, ownerData, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/cms/getBuildiumOwners.ts', 'createBuildiumOwner')
    throw new Error(errorMessage)
  }
}

export async function updateBuildiumOwner(id: string, ownerData: IBuildiumOwner): Promise<IBuildiumOwner> {
  try {
    const response = await api.patch(`/staff/buildium-owners/${id}`, ownerData, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/cms/getBuildiumOwners.ts', 'updateBuildiumOwner')
    throw new Error(errorMessage)
  }
}

export async function deleteBuildiumOwners(id: string): Promise<void> {
  try {
    const response = await api.delete(`/staff/buildium-owners/${id}`, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/cms/getBuildiumOwners.ts', 'deleteBuildiumOwners')
    throw new Error(errorMessage)
  }
}
