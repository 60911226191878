import { makeAutoObservable } from 'mobx'
import { RootStore } from 'stores'
import { handleError } from 'API/error'
import { getRepairs } from 'API/ownerPortal/repair'
import { IRepairGroup } from 'interfaces/repair'

class ownerRepairStore {
  rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  houseList: IRepairGroup[] = []
  totalCount: number = 0

  async fetchRepairList(skip: number, limit: number, selectedDay?: Date) {
    try {
      const { results, totalCount } = await getRepairs(skip, limit, selectedDay)
      this.houseList = results
      this.totalCount = totalCount
    } catch (error) {
      handleError(error as Error, 'stores/ownerRepairStore.ts', 'fetchRepairList')
    }
  }
}

export default ownerRepairStore
