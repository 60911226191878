/* eslint-disable max-lines */
import { EAccountType } from 'enums/user'
import get from 'lodash/get'
import omit from 'lodash/omit'
import { makeAutoObservable } from 'mobx'
import router from 'next/router'
import { toast } from 'react-toastify'
import { RootStore } from 'stores'
import {
  login as loginAPI,
  getUserDetail,
  requestOtp,
  verifyOtp,
  loginWebsiteWithPhoneNumber,
  loginWebsiteWithEmail,
  updateProfile
} from 'API/authenticate'
import { ETokenKey, PLATFORM } from 'API/constants'
import { getUserById } from 'API/customer/user'
import { handleError } from 'API/error'
import { LoginFormData } from 'components/pages/OwnerPortal/AuthenticatePage/components/LoginForm'
import { IUser } from 'interfaces/user'
import routes from 'routes'
import { getAccountType, getAuthenticateStorageKey } from 'utils/common'
import { IWebsiteLoginResponse } from './../interfaces/authenticate/index'

export default class AuthStore {
  rootStore: RootStore
  token?: ETokenKey = undefined
  user: IUser = {}
  isLogin: boolean = false

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  async getMyUser(platform: PLATFORM): Promise<void> {
    this.rootStore.spinnerStore.showLoading()
    try {
      const currentUser = await getUserDetail(platform)
      this.user = currentUser
      this.rootStore.spinnerStore.hideLoading()
    } catch (error) {
      handleError(error as Error, 'stores/authStore.ts', 'getMyUser')
      this.rootStore.spinnerStore.hideLoading()
      const errorMessage: string = get(error, 'message', '')
      throw new Error(errorMessage)
    }
  }

  setAccessToken(accessToken: ETokenKey, isRemember: boolean, platform: PLATFORM): void {
    const token: ETokenKey = getAuthenticateStorageKey(platform)
    if (isRemember) {
      localStorage.setItem(token, accessToken)
    } else {
      sessionStorage.setItem(token, accessToken)
    }
    this.token = accessToken
  }

  setLogin(isLogin: boolean): void {
    this.isLogin = isLogin
  }

  async getAccessToken(platform: PLATFORM): Promise<void> {
    this.rootStore.spinnerStore.showLoading()
    try {
      const currentUser = await getUserDetail(platform)
      this.user = currentUser
      this.rootStore.spinnerStore.hideLoading()
    } catch (error) {
      handleError(error as Error, 'stores/authStore.ts', 'getAccessToken')
      this.rootStore.spinnerStore.hideLoading()
      this.clearAccessToken(platform)
      toast.error('Your session may expired. Please login again !')
    }
  }

  async login(data: LoginFormData, platform: PLATFORM): Promise<void> {
    this.rootStore.spinnerStore.showLoading()
    try {
      const res = await loginAPI(omit(data, 'isRemember'))
      const { token, accountType } = res
      if (accountType && accountType !== getAccountType(platform)) {
        this.rootStore.spinnerStore.hideLoading()
        throw new Error('Wrong Platform, please try again !')
      }
      if (token) {
        if (data?.isRemember) {
          this.setAccessToken(token, true, platform)
        } else {
          this.setAccessToken(token, false, platform)
        }
        this.getMyUser(platform)
        if (platform === PLATFORM.OWNER) {
          router.push(routes.ownerPortal.financial.value)
        } else {
          router.push(routes.cms.applicationManagement.applicant.value)
        }
      }
      this.rootStore.spinnerStore.hideLoading()
      //*INFO: Catch clause variable type annotation must be 'any' or 'unknown' if specified
    } catch (error) {
      this.rootStore.spinnerStore.hideLoading()
      const errorMessage: string = get(error, 'message', '')
      throw new Error(errorMessage)
    }
  }

  async loginWithPhoneNumber(phoneNumber: string): Promise<IWebsiteLoginResponse> {
    this.rootStore.spinnerStore.showLoading()
    try {
      const response: IWebsiteLoginResponse = await loginWebsiteWithPhoneNumber(phoneNumber)
      const { accountType, userId } = response
      if (!userId) {
        this.rootStore.spinnerStore.hideLoading()
        return response
      }
      if (accountType !== EAccountType.APPLICANT) {
        this.rootStore.spinnerStore.hideLoading()
        throw new Error('Wrong Platform, please try again !')
      }
      this.rootStore.spinnerStore.hideLoading()
      return response
    } catch (error) {
      this.rootStore.spinnerStore.hideLoading()
      const errorMessage: string = get(error, 'message', '')
      throw new Error(errorMessage)
    }
  }

  async loginWithEmail(email: string): Promise<IWebsiteLoginResponse> {
    this.rootStore.spinnerStore.showLoading()
    try {
      const response = await loginWebsiteWithEmail(email)
      const { accountType, userId } = response
      if (!userId) {
        this.rootStore.spinnerStore.hideLoading()
        return response
      }
      if (accountType && accountType !== EAccountType.APPLICANT) {
        this.rootStore.spinnerStore.hideLoading()
        throw new Error('Wrong Platform, please try again !')
      }
      this.rootStore.spinnerStore.hideLoading()
      return response
    } catch (error) {
      this.rootStore.spinnerStore.hideLoading()
      const errorMessage: string = get(error, 'message', '')
      throw new Error(errorMessage)
    }
  }

  async setAccessTokenWithPhoneNumber(phoneNumber: string): Promise<void> {
    this.rootStore.spinnerStore.showLoading()
    try {
      const response: IWebsiteLoginResponse = await loginWebsiteWithPhoneNumber(phoneNumber)
      localStorage.setItem(ETokenKey.WEBSITE_ACCESS_TOKEN, response.token)
      this.rootStore.spinnerStore.hideLoading()
      this.getWebsiteAccessToken()
    } catch (error) {
      this.rootStore.spinnerStore.hideLoading()
      const errorMessage: string = get(error, 'message', '')
      throw new Error(errorMessage)
    }
  }

  async setAccessTokenWithEmail(email: string, password: string): Promise<IWebsiteLoginResponse> {
    this.rootStore.spinnerStore.showLoading()
    try {
      const response: IWebsiteLoginResponse = await loginWebsiteWithEmail(email, password)
      if (response?.token && response?.isEmailVerified && response?.userId) {
        localStorage.setItem(ETokenKey.WEBSITE_ACCESS_TOKEN, response.token)
        this.rootStore.spinnerStore.hideLoading()
        this.getWebsiteAccessToken()
        return response
      }
      this.rootStore.spinnerStore.hideLoading()
      return response
    } catch (error) {
      this.rootStore.spinnerStore.hideLoading()
      const errorMessage: string = get(error, 'message', '')
      throw new Error(errorMessage)
    }
  }

  async getWebsiteAccessToken(): Promise<void> {
    this.rootStore.spinnerStore.showLoading()
    try {
      const accessToken: string = localStorage.getItem(ETokenKey.WEBSITE_ACCESS_TOKEN) ?? ''
      if (accessToken) {
        const currentUser = await getUserDetail(PLATFORM.WEBSITE)
        this.user = currentUser
        this.isLogin = true
      }
      this.rootStore.spinnerStore.hideLoading()
    } catch (error) {
      handleError(error as Error, 'stores/authStore.ts', 'getWebsiteAccessToken')
      this.rootStore.spinnerStore.hideLoading()
      this.isLogin = false
      this.clearAccessToken(PLATFORM.WEBSITE)
    }
  }

  clearAccessToken(platform: PLATFORM): void {
    const token: ETokenKey = getAuthenticateStorageKey(platform)
    localStorage.removeItem(token)
    sessionStorage.removeItem(token)
    this.token = undefined
    this.user = {}
    if (platform === PLATFORM.OWNER) {
      router.replace(routes.ownerPortal.login.value)
    } else if (platform === PLATFORM.CMS) {
      router.replace(routes.cms.login.value)
    } else {
      this.isLogin = false
    }
  }

  async requestOtpToken(phone: string): Promise<void> {
    try {
      await requestOtp(phone)
    } catch (error) {
      const errorMessage: string = get(error, 'message', '')
      handleError(error as Error, 'stores/authStore.ts', 'requestOtp')
      throw new Error(errorMessage)
    }
  }

  async verifyOtpToken(token: string, phone: string): Promise<boolean> {
    try {
      const isValid = await verifyOtp(token, phone)
      return isValid
    } catch (error) {
      handleError(error as Error, 'stores/authStore.ts', 'verifyOtp')
      return false
    }
  }

  async updateUserProfile(userData: IUser, platform: PLATFORM): Promise<void> {
    try {
      await updateProfile(userData, platform)
      await this.getMyUser(platform)
    } catch (error) {
      const errorMessage: string = get(error, 'message', '')
      handleError(error as Error, 'stores/authStore.ts', 'updateUserProfile')
      throw new Error(errorMessage)
    }
  }

  async getMyUserById(userId: string): Promise<void> {
    this.rootStore.spinnerStore.showLoading()
    try {
      const currentUser: IUser = await getUserById(userId)
      this.user = currentUser
      this.rootStore.spinnerStore.hideLoading()
    } catch (error) {
      handleError(error as Error, 'stores/authStore.ts', 'getMyUserById')
      this.rootStore.spinnerStore.hideLoading()
      const errorMessage: string = get(error, 'message', '')
      throw new Error(errorMessage)
    }
  }
}
