import { makeAutoObservable } from 'mobx'
import { RootStore } from 'stores'
import { IFilter } from 'types/query'
import {
  getOwnerPaymentTrackerList,
  postOwnerPaymentTracker,
  getOwnerPaymentTracker,
  patchOwnerPaymentTracker,
  countOwnerPaymentTracker
} from 'API/cms/ownerPaymentTracker'
import { handleError } from 'API/error'
import { EPaymentTrackerAmenitiesPayor } from 'components/pages/CMS/OwnerPortalInformation/PaymentTrackerManagement/PaymentDetail/enum'
import { EPropertyPayoutType } from 'components/pages/OwnerPortal/FinancialPage/enum'
import { IOwnerPaymentTracker } from 'interfaces/ownerPaymentTracker'

class OwnerPaymentTrackerStore {
  rootStore: RootStore
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  ownerPaymentTrackerList: IOwnerPaymentTracker[] = []
  totalCount: number = 0

  ownerPaymentTracker: IOwnerPaymentTracker = {
    id: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    buildiumPropertyId: 0,
    year: 0,
    payoutType: EPropertyPayoutType.GUARANTEED,
    amenitiesPayor: EPaymentTrackerAmenitiesPayor.HOMEROOM,
    payoutPercent: 0,
    totalRentCollected: 0,
    payment: 0,
    balancePostPayment: 0
  }

  async fetchOwnerPaymentTrackerList(filter: IFilter<IOwnerPaymentTracker> = {}) {
    try {
      const [ownerPaymentTrackerList, totalCount] = await Promise.all([
        getOwnerPaymentTrackerList(filter),
        countOwnerPaymentTracker(filter)
      ])
      this.ownerPaymentTrackerList = ownerPaymentTrackerList
      this.totalCount = totalCount
    } catch (error) {
      handleError(error as Error, 'stores/cms/cmsOwnerPaymentTrackerStore.ts', 'fetchOwnerPaymentTrackerList')
    }
  }
  async fetchOwnerPaymentTracker(id: string, filter: IFilter<IOwnerPaymentTracker> = {}) {
    try {
      this.ownerPaymentTracker = await getOwnerPaymentTracker(id, filter)
    } catch (error) {
      handleError(error as Error, 'stores/cms/cmsOwnerPaymentTrackerStore.ts', 'fetchOwnerPaymentTracker')
    }
  }
  async createOwnerPaymentTracker(data: IOwnerPaymentTracker) {
    try {
      await postOwnerPaymentTracker(data)
    } catch (error) {
      handleError(error as Error, 'stores/cms/cmsOwnerPaymentTrackerStore.ts', 'createOwnerPaymentTracker')
    }
  }
  async updateOwnerPaymentTracker(id: string, data: IOwnerPaymentTracker) {
    try {
      await patchOwnerPaymentTracker(id, data)
    } catch (error) {
      handleError(error as Error, 'stores/cms/cmsOwnerPaymentTrackerStore.ts', 'updateOwnerPaymentTracker')
    }
  }
}
export default OwnerPaymentTrackerStore
