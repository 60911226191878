import dayjs, { Dayjs } from 'dayjs'
import first from 'lodash/first'
import { makeAutoObservable } from 'mobx'
import { RootStore } from 'stores'
import { IFilter } from 'types/query'
import { getContinueLinkToApplyingForApplicant, getUserApplications } from 'API/applicant'
import { handleError } from 'API/error'
import {
  checkPaymentExpiration,
  getHouseApplication,
  getHouseApplications,
  getRoommatesOfHouse,
  updateHouseApplication
} from 'API/houseApplication'
import { getAddressData } from 'API/houseApplication/mapbox'
import {
  EApplicationCompletedState,
  EApplicationSteps
} from 'components/pages/DetailPage/components/ApplicationFormModal/constant'
import {
  IAddressRequest,
  IAddressSuggestion,
  IHouseApplication,
  IHouseApplicationWithRelations
} from 'interfaces/application'
import { IBuildiumTenant } from 'interfaces/buildium'
import { getValidArray } from 'utils/common'

class HouseApplicationStore {
  rootStore: RootStore
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  roommates: IBuildiumTenant[] = []
  houseApplication: IHouseApplication = {} as IHouseApplication
  prefillHouseApplication: IHouseApplication = {} as IHouseApplication
  isTestPhoneNumber: boolean = false
  isPaymentExpired: boolean = true
  houseApplications: IHouseApplicationWithRelations[] = []
  promotionCode: string = ''

  public setPromotionCode(promotionCode: string): void {
    this.promotionCode = promotionCode
  }

  public async fetchRoommates(buildiumPropertyId: number): Promise<void> {
    const roommates = await getRoommatesOfHouse(buildiumPropertyId)

    this.roommates = roommates
  }

  public async fetchHouseApplication(
    phoneNumber: string,
    buildiumUnitId: number,
    callback?: (isValidApplication: boolean) => Promise<void>,
    isPrefilled?: boolean
  ): Promise<void> {
    const filter: IFilter<IHouseApplication> = {
      where: isPrefilled
        ? {
            phoneNumber: encodeURIComponent(phoneNumber)
          }
        : {
            phoneNumber: encodeURIComponent(phoneNumber),
            buildiumUnitId
          },
      order: ['updatedAt DESC'],
      limit: 1
    }
    const [houseApplications, isPaymentExpired] = await Promise.all([
      getHouseApplications(filter),
      checkPaymentExpiration(phoneNumber, buildiumUnitId)
    ])
    this.isPaymentExpired = isPaymentExpired

    const newestHouseApplication: IHouseApplication | undefined = first(houseApplications)
    if (!newestHouseApplication) {
      if (typeof callback === 'function') {
        callback(false)
      }

      return
    }

    // INFO: the application valid for 24 hours after submitted
    const updatedAt: Date | undefined = newestHouseApplication?.updatedAt
    const maxDate: Dayjs = dayjs(updatedAt).add(1, 'day')
    const isValidApplication: boolean = dayjs().isBefore(maxDate) && newestHouseApplication?.isDone === true
    this.houseApplication = newestHouseApplication
    this.prefillHouseApplication = isPrefilled ? newestHouseApplication : ({} as IHouseApplication)

    if (typeof callback === 'function') {
      // INFO: check if prefill house application buildiumUnitId is being duplicated
      if (this.prefillHouseApplication?.buildiumUnitId === buildiumUnitId) {
        callback(isValidApplication)
      } else {
        callback(false)
      }
    }
  }

  public async updateHouseApplication(data: Partial<IHouseApplication>): Promise<void> {
    if (this.houseApplication?.id) {
      await updateHouseApplication(this.houseApplication.id, data)
    }
  }

  public async fetchHouseApplicationById(
    id: string,
    phoneNumber: string,
    buildiumUnitId: number,
    callback?: (isValidApplication: boolean) => Promise<void>,
    isPrefilled?: boolean
  ): Promise<void> {
    try {
      const [houseApplication, isPaymentExpired] = await Promise.all([
        getHouseApplication(id),
        checkPaymentExpiration(phoneNumber, buildiumUnitId)
      ])
      this.isPaymentExpired = isPaymentExpired
      if (!houseApplication) {
        if (typeof callback === 'function') {
          callback(false)
        }

        return
      }

      // INFO: the application valid for 24 hours after submitted
      const updatedAt: Date | undefined = houseApplication?.updatedAt
      const maxDate: Dayjs = dayjs(updatedAt).add(1, 'day')
      const lastCompletedStep: number = Object.values(EApplicationCompletedState).indexOf(
        houseApplication.lastCompletedState as EApplicationCompletedState
      )
      if (lastCompletedStep > EApplicationSteps.SELECT_SOURCE) {
        //* Set isDone = true if form is full filled
        houseApplication.isDone = true
      }
      const isValidApplication: boolean =
        (dayjs().isBefore(maxDate) && houseApplication?.isDone === true) ||
        lastCompletedStep > EApplicationSteps.SELECT_SOURCE

      this.houseApplication = houseApplication
      this.prefillHouseApplication = isPrefilled ? houseApplication : ({} as IHouseApplication)

      if (typeof callback === 'function') {
        callback(isValidApplication)
      }
    } catch (error) {
      handleError(error as Error, 'stores/houseApplicationStore.ts', 'fetchHouseApplicationById')
    }
  }

  public async fetchHouseApplicationByPhoneNumber(phoneNumber: string): Promise<void> {
    const filter: IFilter<IHouseApplication> = {
      where: {
        // TODO: Add more conditions to check if phoneNumber got background check
        phoneNumber: encodeURIComponent(phoneNumber)
      },
      order: ['updatedAt DESC'],
      limit: 1
    }
    const houseApplications: IHouseApplication[] = await getHouseApplications(filter)
    const newestHouseApplication: IHouseApplication | undefined = first(houseApplications)
    if (!newestHouseApplication) {
      this.houseApplication = {} as IHouseApplication
      return
    }
    this.houseApplication = newestHouseApplication
  }

  public setIsTestPhoneNumber(isTesting: boolean): void {
    this.isTestPhoneNumber = isTesting
  }

  public async getMapboxAddress(addressData: IAddressRequest): Promise<IAddressSuggestion[]> {
    const addressDataResponse = await getAddressData(addressData)
    return addressDataResponse
  }

  public async fetchHouseApplications(): Promise<void> {
    const houseApplications: IHouseApplicationWithRelations[] = await getUserApplications()
    this.houseApplications = houseApplications
  }

  public async deleteHouseApplicationById(id: string, data: Partial<IHouseApplication>): Promise<void> {
    await updateHouseApplication(id, data)
    this.houseApplications = getValidArray(this.houseApplications).filter(
      (houseApplication: IHouseApplication) => houseApplication.id !== id
    )
  }

  public async fetchApplicantContinueApplyingLink(id: string, filter = {}): Promise<string> {
    try {
      return getContinueLinkToApplyingForApplicant(id, filter)
    } catch (error) {
      handleError(error as Error, 'stores/cms/cmsApplicationStore.ts', 'fetchApplicantContinueApplyingLink')
      return ''
    }
  }
}

export default HouseApplicationStore
