import { makeAutoObservable } from 'mobx'
import { RootStore } from 'stores'
import {
  getApplicantDetail,
  getApplicants,
  getContinueLinkToApplyingForApplicant,
  updateApplicant as updateApplicantAPI
} from 'API/cms/application'
import { PaginationList } from 'API/constants'
import { handleError } from 'API/error'
import { IApplicant, IHouseApplication, IHouseApplicationWithRelations } from 'interfaces/application'

class ApplicationStore {
  rootStore: unknown

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }
  applicantList: IApplicant[] = []

  allApplicantList: IApplicant[] = []

  applicantsLength: number = 0

  applicantDetail: IHouseApplicationWithRelations = {} as IHouseApplicationWithRelations

  applicantContinueApplyingLink: string = ''

  async fetchApplicants(filter = {}): Promise<void> {
    try {
      const applicants: PaginationList<IApplicant> = await getApplicants(filter)
      this.applicantList = applicants?.results ?? []
      this.applicantsLength = applicants?.totalCount ?? 0
    } catch (error) {
      handleError(error as Error, 'stores/cms/cmsApplicationStore.ts', 'fetchApplicants')
    }
  }

  async fetchAllApplicants(): Promise<void> {
    try {
      const allApplicants: PaginationList<IApplicant> = await getApplicants({})
      this.allApplicantList = allApplicants?.results ?? []
    } catch (error) {
      handleError(error as Error, 'stores/cms/cmsApplicationStore.ts', 'fetchAllApplicants')
    }
  }

  async fetchApplicantDetail(id: string, filter = {}): Promise<void> {
    try {
      const applicant: IHouseApplicationWithRelations = await getApplicantDetail(id, filter)
      this.applicantDetail = applicant
    } catch (error) {
      handleError(error as Error, 'stores/cms/cmsApplicationStore.ts', 'fetchApplicantDetail')
    }
  }

  async fetchApplicantContinueApplyingLink(id: string, filter = {}): Promise<void> {
    try {
      const applicantContinueApplyingLink: string = await getContinueLinkToApplyingForApplicant(id, filter)
      this.applicantContinueApplyingLink = applicantContinueApplyingLink
    } catch (error) {
      handleError(error as Error, 'stores/cms/cmsApplicationStore.ts', 'fetchApplicantContinueApplyingLink')
    }
  }

  async updateApplicant(id: string, applicant: Partial<IHouseApplication>): Promise<void> {
    try {
      await updateApplicantAPI(id, applicant)
    } catch (error) {
      handleError(error as Error, 'stores/cms/cmsApplicationStore.ts', 'updateApplicant')
    }
  }

  resetStore() {
    this.applicantDetail = {} as IHouseApplicationWithRelations
    this.applicantList = []
  }
}

export default ApplicationStore
