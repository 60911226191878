import { makeAutoObservable } from 'mobx'
import { RootStore } from 'stores'
import { PaginationList } from 'types'
import { IFilter } from 'types/query'
import { getFinancialFiles, removeFinancialFiles, uploadFinancialFiles } from 'API/cms/financialUploader'
import { handleError } from 'API/error'
import { IFinancialFile } from 'interfaces/financialUploader'

class FinancialUploaderStore {
  rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  financialFiles: PaginationList<IFinancialFile> = {
    results: [],
    totalCount: 0
  }

  allFinancialFiles: PaginationList<IFinancialFile> = {
    results: [],
    totalCount: 0
  }

  async postFinancialFile(file: File): Promise<IFinancialFile> {
    const response: IFinancialFile = await uploadFinancialFiles(file)
    return response
  }

  async fetchFinancialFiles(filter: IFilter<IFinancialFile> = {}): Promise<void> {
    try {
      const financialFiles: PaginationList<IFinancialFile> = await getFinancialFiles(filter)
      const allFinancialFiles: PaginationList<IFinancialFile> = await getFinancialFiles({})
      this.financialFiles = financialFiles
      this.allFinancialFiles = allFinancialFiles
    } catch (error) {
      handleError(error as Error, 'stores/cms/cmsFinancialUploaderStore.ts', 'fetchFinancialFiles')
    }
  }

  async deleteFinancialFile(id: string) {
    await removeFinancialFiles(id)
  }
}

export default FinancialUploaderStore
