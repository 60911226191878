/* eslint-disable max-lines */
import { PaymentIntent } from '@stripe/stripe-js'
import { AxiosResponse } from 'axios'
import get from 'lodash/get'
import { IFilter } from 'types/query'
import { api } from 'API'
import { handleError } from 'API/error'
import {
  IBerbixTransaction,
  IBerbixTransactionResponse
} from 'components/pages/DetailPage/components/ApplicationFormModal/constant'
import { IApplicationFormValues, IHouseApplication } from 'interfaces/application'
import { IBuildiumTenant } from 'interfaces/buildium'

const BASE_PATH: string = '/customer/house-applications'

export async function createDriverLicenseVerification(customerUid: string): Promise<IBerbixTransactionResponse> {
  try {
    const url = `${BASE_PATH}/create-berbix-transaction`
    const response = await api.post(url, JSON.stringify(customerUid), {
      headers: { 'Content-Type': 'application/json' }
    })
    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/houseApplication', 'createDriverLicenseVerification')
    throw new Error(errorMessage)
  }
}

export async function getRoommatesOfHouse(buildiumPropertyId: number): Promise<IBuildiumTenant[]> {
  try {
    const url: string = `${BASE_PATH}/${buildiumPropertyId}/roommates`
    const response: AxiosResponse = await api.get(url)
    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/houseApplication', 'getRoommatesOfHouse')
    throw new Error(errorMessage)
  }
}

export async function getDriverLicenseVerification(
  refreshToken: string,
  houseId: number,
  buildiumUnitId: number
): Promise<IBerbixTransaction> {
  try {
    const url = `${BASE_PATH}/berbix-transactions`
    const response = await api.post(
      url,
      {
        refreshToken,
        houseId,
        buildiumUnitId
      },
      {
        headers: { 'Content-Type': 'application/json' }
      }
    )
    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/houseApplication', 'getDriverLicenseVerification')
    throw new Error(errorMessage)
  }
}

export async function getDriverLicenseVerificationWithoutHouseInfo(
  refreshToken: string,
  houseApplicationId?: string
): Promise<IBerbixTransaction> {
  try {
    const url = `${BASE_PATH}/berbix-transactions-without-house`
    const response: AxiosResponse = await api.post(
      url,
      {
        refreshToken,
        houseApplicationId
      },
      {
        headers: { 'Content-Type': 'application/json' }
      }
    )
    return response?.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/houseApplication', 'getDriverLicenseVerificationWithoutHouseInfo')
    throw new Error(errorMessage)
  }
}

export async function sendSMSToRoommates(
  applicantName: string,
  applicantPhone: string,
  roommatePhones: string[]
): Promise<void> {
  try {
    const url: string = `${BASE_PATH}/roommates/notify-new-application`

    await api.post(url, {
      applicantName,
      applicantPhone,
      roommatePhones
    })
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/houseApplication', 'sendSMSToRoommates')
    throw new Error(errorMessage)
  }
}

export async function sendNotifyToCustomerService(application: IHouseApplication): Promise<void> {
  try {
    const url: string = `${BASE_PATH}/customer-service/notify-new-application`

    await api.post(url, application)
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/houseApplication', 'sendNotifyToCustomerService')
    throw new Error(errorMessage)
  }
}

export async function sendLeaseAgreementEmail(
  applicantName: string,
  applicantEmail: string,
  address: string,
  houseDetailUrl: string,
  signLeaseAgreementUrl: string
): Promise<void> {
  try {
    const url: string = `${BASE_PATH}/send-lease-agreement-email`

    await api.post(url, {
      applicantName,
      applicantEmail,
      address,
      houseDetailUrl,
      signLeaseAgreementUrl
    })
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/houseApplication', 'sendNotifyToCustomerService')
    throw new Error(errorMessage)
  }
}

export async function getHouseApplications(filter?: IFilter): Promise<IHouseApplication[]> {
  try {
    const url: string = `${BASE_PATH}?filter=${JSON.stringify(filter ?? {})}`
    const response: AxiosResponse = await api.get(url)
    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/houseApplication', 'getHouseApplications')
    throw new Error(errorMessage)
  }
}

export async function getHouseApplication(id: string, filter?: IFilter): Promise<IHouseApplication> {
  try {
    const url: string = `${BASE_PATH}/${id}?filter=${JSON.stringify(filter ?? {})}`
    const response: AxiosResponse = await api.get(url)

    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/houseApplication', 'getHouseApplication')
    throw new Error(errorMessage)
  }
}

export async function updateHouseApplication(id: string, houseApplication: Partial<IHouseApplication>): Promise<void> {
  try {
    const url: string = `${BASE_PATH}/${id}`
    await api.patch(url, houseApplication)
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/houseApplication', 'updateHouseApplication')
    throw new Error(errorMessage)
  }
}

export async function updatePaymentInfo(
  phoneNumber: string,
  status: PaymentIntent.Status,
  paymentIntentId: string
): Promise<void> {
  try {
    const url: string = `${BASE_PATH}/update-payment-info`
    await api.post(url, {
      phoneNumber,
      status,
      paymentIntentId
    })
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/houseApplication', 'updatePaymentDate')
    throw new Error(errorMessage)
  }
}

export async function checkPaymentExpiration(phoneNumber: string, buildiumUnitId: number): Promise<boolean> {
  try {
    const url: string = `${BASE_PATH}/check-payment-expiration`
    const { data } = await api.post(url, {
      phoneNumber,
      buildiumUnitId
    })
    return true
    return data?.isPaymentExpired ?? true
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/houseApplication', 'checkPaymentExpiration')
    throw new Error(errorMessage)
  }
}

export async function sendNotificationSMS(
  applicantName: string,
  phoneNumber?: string,
  buildiumPropertyId?: number,
  buildiumUnitId?: number
): Promise<void> {
  try {
    const url: string = `${BASE_PATH}/send-notification-sms`
    await api.patch(url, {
      applicantName,
      phoneNumber,
      buildiumUnitId,
      buildiumPropertyId
    })
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/houseApplication', 'sendNotificationSMS')
    throw new Error(errorMessage)
  }
}

export async function upsertHousesApplicationByApplicationId(
  houseApplication: IApplicationFormValues,
  applicationId?: string
): Promise<IApplicationFormValues> {
  try {
    const url: string = `${BASE_PATH}/upsert-by-applicationId`
    const response: AxiosResponse = await api.patch(url, {
      houseApplication,
      applicationId
    })
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/houseApplication', 'upsertHousesApplicationByApplicationId')
    return {}
  }
}
