import dayjs from 'dayjs'
import { api, auth } from 'API'
import { PaginationList, PLATFORM } from 'API/constants'
import { handleError } from 'API/error'
import { IPropertyFinancial } from 'components/pages/OwnerPortal/FinancialPage/enum'
import { IFinancialOverall } from 'interfaces/financial'

export async function getFinancials(
  skip: number = 0,
  limit: number = 0,
  selectedYear: string = '',
  propertyIds: number[] = []
): Promise<PaginationList<IPropertyFinancial>> {
  try {
    const response = await api.get(
      `owner/financials?${
        selectedYear && `year=${selectedYear}`
      }&skip=${skip}&limit=${limit}&property-ids=${propertyIds}`,
      {
        headers: auth(PLATFORM.OWNER)
      }
    )
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/ownerPortal/financial', 'getFinancials')
    return { results: [], totalCount: 0 }
  }
}

export async function getFinancialsOverall(monthYear: Date): Promise<IFinancialOverall> {
  try {
    const response = await api.get(`owner/financials/overall?month=${dayjs(monthYear).format('MMM YYYY')}`, {
      headers: auth(PLATFORM.OWNER)
    })
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/ownerPortal/financial', 'getFinancialsOverall')
    return { totalIncome: 0, totalExpense: 0, numberOfProperty: 0 }
  }
}
