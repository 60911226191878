import { IFilter } from 'types/query'
import { api } from 'API'
import { handleError } from 'API/error'
import { IMetro, IPricingTableSettings } from 'interfaces/metro'

export async function getMetros(filter: IFilter<IMetro> = {}): Promise<IMetro[]> {
  try {
    const response = await api.get(`/metros?filter=${JSON.stringify(filter)}`)
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/metro', 'getMetros')
    return []
  }
}

export async function getPricingTableSettings(): Promise<IPricingTableSettings[]> {
  try {
    const response = await api.get(`/pricing-table-settings`)
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/metro', 'getPricingTableSettings')
    return []
  }
}
