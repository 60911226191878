import { makeAutoObservable } from 'mobx'
import { RootStore } from 'stores'
import { getCMSPolicyValue, updateCMSPolicyValue } from 'API/cms/policy'
import { handleError } from 'API/error'
import { IPolicyValue } from 'interfaces/policy'

class PolicyStore {
  rootStore: RootStore

  sharedHousePolicies: string[] = []

  entireHousePolicies: string[] = []

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  async fetchCMSPolicyValue(): Promise<void> {
    try {
      const policyValue: IPolicyValue = await getCMSPolicyValue()
      const { sharedHousePolicies, entireHousePolicies } = policyValue
      this.sharedHousePolicies = sharedHousePolicies ?? []
      this.entireHousePolicies = entireHousePolicies ?? []
    } catch (error) {
      handleError(error as Error, 'stores/cms/cmsPolicyStore.ts', 'fetchCMSPolicyValue')
    }
  }

  async updatePolicyValue(policyValueData: IPolicyValue): Promise<void> {
    try {
      await updateCMSPolicyValue(policyValueData)
    } catch (error) {
      handleError(error as Error, 'stores/cms/cmsPolicyStore.ts', 'updatePolicyValue')
    }
  }
}

export default PolicyStore
