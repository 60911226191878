import { makeAutoObservable } from 'mobx'
import { RootStore } from 'stores'
import { IFilter } from 'types/query'
import { getCities } from 'API/city'
import { handleError } from 'API/error'
import { ICity } from 'interfaces/city'

class CityStore {
  rootStore: RootStore
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  cityList: ICity[] = []
  cityDetail: ICity = {
    id: '',
    name: ''
  }

  get countCityList(): number {
    return this.cityList.length
  }

  async setCityList(cityList: ICity[]) {
    this.cityList = cityList
  }

  async fetchCityList(filter: IFilter<ICity> = {}) {
    try {
      this.cityList = await getCities(filter)
    } catch (error) {
      handleError(error as Error, 'stores/cityStore.ts', 'fetchCityList')
    }
  }
}

export default CityStore
