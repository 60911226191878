import { IFilter } from 'types/query'
import { api } from 'API'
import { handleError } from 'API/error'
import { ICity } from 'interfaces/city'

export async function getCities(filter: IFilter<ICity>): Promise<ICity[]> {
  try {
    const response = await api.get(`/cities?filter=${JSON.stringify(filter)}`)
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/city', 'getCities')
    return []
  }
}
