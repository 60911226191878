import { ICRSCriminalTenantData, ICRSEvictionTenantData, ICRSTenantData } from 'interfaces/application'
import { IOption } from 'interfaces/common'
import { ICustomerServiceInfo } from 'interfaces/user'
import { IUnit } from './../../../../../interfaces/unit/index'
import { MIN_FICO_CREDIT_SCORE, MIN_FICO_ENTIRE_HOUSE_CREDIT_SCORE } from './components/CheckoutForm/constants'

export const STRIPE_PUBLISHABLE_KEY: string = process!.env!.STRIPE_PUBLISHABLE_KEY!

export const refererSource: string[] = [
  'Apartments.com',
  'Craigslist',
  'Facebook Group',
  'Facebook Marketplace',
  'Facebook Ad',
  'Livehomeroom.com',
  'Realtor.com',
  'RentPath',
  'Roomster',
  'Zumper',
  'Other'
]

export const requirements: string[] = [
  `Credit score of ${MIN_FICO_CREDIT_SCORE}+`,
  'Over the age of 18',
  'No criminal or eviction history',
  'No pets',
  'One occupant per room',
  'Monthly income greater than 2x rent'
]

export const entireHouseRequirements: string[] = [
  `Credit score of ${MIN_FICO_ENTIRE_HOUSE_CREDIT_SCORE}+`,
  'Over the age of 18',
  'No criminal or eviction history'
]

export const sourceOptions: IOption[] = refererSource.map((source) => ({
  label: source,
  value: source
}))

export const homeRoomSupportTeamInfo: ICustomerServiceInfo = {
  fullName: 'HomeRoom Customer Support Team',
  phoneNumber: '(208) 555-0112'
}

export interface IApplicationFormModalProps {
  isOpen: boolean
  onClose: () => void
  phoneNumber: string
  buildiumUnitId: number
  buildiumPropertyId?: number
  address?: string
  shortAddress: string
  unit?: IUnit
  isEntireHouse?: boolean
  isContinueApplying?: boolean
  houseApplicationId?: string
}

export interface IBerbixTransactionResponse {
  refreshToken: string
  hostedUrl: string
}

export interface IBerbixTransaction {
  id: string
  entity?: string
  transactionId: number
  action?: string
  driverLicenseFrontPhotoUrl?: string
  driverLicenseBackPhotoUrl?: string
  createdAt: Date
  updatedAt: Date
  completedAt: Date
  customerUid?: string
  isDuplicated?: boolean
  dashboardUrl?: string
}

export enum EDriverLicenseTypeEnum {
  ACCEPT = 'accept',
  REVIEW = 'review',
  REJECT = 'reject'
}
export enum EApplicationSteps {
  OTP_VERIFICATION = 0,
  PERSONAL_INFORMATION = 1,
  MOVE_IN_DAY = 2,
  BACKGROUND_INFORMATION = 3,
  DRIVER_LICENSE = 4,
  PAYMENT_INFORMATION = 5,
  MOST_RECENT_ADDRESS = 6,
  PAYSTUB_UPLOADER = 7,
  SELECT_SOURCE = 8
}

export enum EApplicationCompletedState {
  PHONE_NUMBER_SUBMITTED = 'Phone number submitted',
  OTP_VERIFIED = 'OTP verified',
  BASIC_INFORMATION_SUBMITTED = 'Basic information submitted',
  MOVE_IN_DAY_SUBMITTED = 'Move in date submitted',
  BACKGROUND_INFORMATION_SUBMITTED = 'Background information submitted',
  DRIVERS_LICENSE_SUBMITTED = 'Drivers license submitted',
  APPLICATION_FEE_PAYMENT_RECEIVED = 'Application fee payment received',
  MOST_RECENT_ADDRESS_SUBMITTED = 'Most recent address submitted',
  PAYSTUB_UPLOADER_SUBMITTED = 'Paystub uploader submitted',
  FULL_APPLICATION_COMPLETED = 'Full application completed',
  BLANK = ''
}

export enum EBottomButtonValue {
  BACK = 'Back',
  CONTINUE = 'Continue',
  SUBMIT = 'Submit Application',
  COMPLETE = 'Complete'
}

// INFO: mock data using for testing CRS check on SandBox
export const TEST_CRS_EMAIL_SUB: string = '.test-crs@yopmail.com'
export const TEST_CRS_CREDIT_SCORE_MOCK_DATA: ICRSTenantData = {
  firstName: 'daisy',
  lastName: 'oogrd',
  ssn: '666268141',
  dob: '1969-01-27',
  city: 'HONOLULU',
  state: 'HI',
  phone: '0000000000'
}

export const TEST_CRS_CRIMINAL_MOCK_DATA: ICRSCriminalTenantData = {
  last: 'Consumer',
  first: 'Jonathan',
  dob: '01-01-1982',
  ssn: '666-44-3321',
  city: 'Cottonwood',
  state: 'CA',
  zip: '91502'
}

export const TEST_CRS_EVICTION_MOCK_DATA: ICRSEvictionTenantData = {
  last: 'Consumer',
  first: 'Jonathan',
  dob: '01-01-1982',
  ssn: '666-44-3321',
  houseNumber: '1803',
  streetName: 'Norma',
  city: 'Cottonwood',
  state: 'CA',
  zip: '91502'
}

export const DOCUSIGN_LOADING_PAGE_URL: string = `${process.env.FE_BASE_URL}/docusign-loading`

export const SECURITY_DEPOSIT: number = 350

export const APPLICATION_FEE: number = 35
export const APPLICATION_TEST_FEE: number = 0.5

export const PAYSTUBS_REQUIRED_QUANTITY: number = 3

// TODO: May use later
// export const applicationFormSchema = yup.object({
//   firstName: yup
//     .string()
//     .matches(/^[aA-zZ\s]+$/, 'Please enter a valid name')
//     .required('First name is required'),
//   lastName: yup
//     .string()
//     .matches(/^[aA-zZ\s]+$/, 'Please enter a valid name')
//     .required('Last name is required'),
//   email: yup.string().email('Please enter a valid email').required('Email is required'),
//   phoneNumber: yup
//     .string()
//     .min(10, 'Phone number must have more than 10 digits')
//     .max(20, 'Phone number must have less than 20 digits')
//     .required('Phone number is required'),
//   dateOfBirth: yup
//     .string()
//     .matches(/^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/, 'Please enter a valid date')
//     .required('Date of birth is required'),
//   ssn: yup.string().required('SSN is required'),
//   driverLicensePhotoUrl: yup.string().url().required('Driver license is required'),
//   referrers: yup.array().of(string()).required('Referrers is required')
// })
