import { makeAutoObservable } from 'mobx'
import { RootStore } from 'stores'
import { PaginationList } from 'types'
import { IFilter } from 'types/query'
import {
  getAccountDetail,
  getAccounts,
  createAccount as createAccountAPI,
  updateAccount as updateAccountAPI
} from 'API/cms/account'
import { handleError } from 'API/error'
import { IUser } from 'interfaces/user'

class AccountStore {
  rootStore: unknown

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }
  cmsAccountList: PaginationList<IUser> = {
    results: [],
    totalCount: 0
  }

  accountDetail?: IUser = undefined

  async fetchCMSAccountList(filter: IFilter<IUser> = {}): Promise<void> {
    try {
      const accountList: PaginationList<IUser> = await getAccounts(filter)
      this.cmsAccountList = accountList
    } catch (error) {
      handleError(error as Error, 'stores/cms/cmsAccountStore.ts', 'fetchCMSAccountList')
    }
  }

  async fetchAccountDetail(id: string, filter: IFilter<IUser> = {}): Promise<void> {
    try {
      const account: IUser = await getAccountDetail(id, filter)
      this.accountDetail = account
    } catch (error) {
      handleError(error as Error, 'stores/cms/cmsAccountStore.ts', 'fetchAccountDetail')
    }
  }

  async createAccount(account: IUser): Promise<void> {
    try {
      const newAccount: IUser = await createAccountAPI(account)
      this.accountDetail = newAccount as IUser
    } catch (error) {
      handleError(error as Error, 'stores/cms/cmsAccountStore.ts', 'createAccount')
    }
  }

  async updateAccount(id: string, owner: IUser): Promise<void> {
    try {
      await updateAccountAPI(id, owner)
    } catch (error) {
      handleError(error as Error, 'stores/cms/cmsAccountStore.ts', 'updateAccount')
    }
  }

  resetStore(): void {
    this.accountDetail = undefined
  }
}

export default AccountStore
