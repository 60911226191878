import get from 'lodash/get'
import { PaginationList } from 'types'
import { IFilter } from 'types/query'
import { auth } from 'API'
import { PLATFORM } from 'API/constants'
import { handleError } from 'API/error'
import { IMedia } from 'interfaces/media'
import { IProperty, IPropertyWithRelations } from 'interfaces/property'
import { api } from '..'

export async function getCMSHouses(filter: IFilter<IProperty>): Promise<PaginationList<IProperty>> {
  try {
    const response = await api.get(`/houses?filter=${JSON.stringify(filter)}`)
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/property', 'getCMSHouses')
    return { results: [], totalCount: 0 }
  }
}

export async function getCMSHouseList(filter: IFilter<IProperty>): Promise<PaginationList<IProperty>> {
  try {
    const response = await api.get(`/staff/properties?filter=${JSON.stringify(filter)}`, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/property', 'getCMSHouseList')
    return { results: [], totalCount: 0 }
  }
}

export async function getCMSHouseDetailByBuildiumPropertyId(
  buildiumPropertyId: number,
  filter: IFilter<IPropertyWithRelations>
): Promise<IPropertyWithRelations> {
  try {
    const response = await api.get(
      `/staff/properties/find-one-by-buildium-property-id/${buildiumPropertyId}?filter=${JSON.stringify(filter)}`,
      {
        headers: auth(PLATFORM.CMS)
      }
    )
    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/property', 'getCMSHouseDetailByBuildiumPropertyId')
    throw new Error(errorMessage)
  }
}

export async function getCMSHouseDetail(
  houseId: string,
  filter: IFilter<IPropertyWithRelations>
): Promise<IPropertyWithRelations> {
  try {
    const response = await api.get(`/staff/properties/${houseId}?filter=${JSON.stringify(filter)}`, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/property', 'getCMSHouses')
    throw new Error(errorMessage)
  }
}

export async function updateCMSHouse(id: string, propertyData: Partial<IProperty>): Promise<IProperty> {
  try {
    const response = await api.patch(`/staff/properties/${id}`, propertyData, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/cms/property.ts', 'updateHouse')
    throw new Error(errorMessage)
  }
}

export async function reorderHouseMedia(sortedMediaIds: string[]): Promise<void> {
  try {
    await api.patch(`/staff/properties/media-order`, sortedMediaIds, {
      headers: auth(PLATFORM.CMS)
    })
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/cms/property.ts', 'reorderHouseMedia')
    throw new Error(errorMessage)
  }
}

export async function deleteHouse(id: string): Promise<void> {
  try {
    const response = await api.delete(`/staff/properties/${id}`, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/cms/property.ts', 'deleteHouse')
    throw new Error(errorMessage)
  }
}

export async function uploadFloorPlanMedia(id: string, file: File): Promise<IMedia> {
  const body = new FormData()
  body.append('file', file, file.name)
  try {
    const response = await api.put(`/staff/properties/${id}/upload-floorplan-media`, body, {
      headers: { ...auth(PLATFORM.CMS), 'Content-Type': 'multipart/form-data' }
    })
    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/cms/property.ts', 'uploadFloorPlanMedia')
    throw new Error(errorMessage)
  }
}

// *TODO: Intergrate New Upload OwnerLease File
export async function uploadOwnerLeaseFile(id: string, file: File): Promise<string> {
  const body = new FormData()
  body.append('file', file, file.name)
  try {
    const response = await api.patch(`/staff/properties/${id}/upload-owner-lease`, body, {
      headers: { ...auth(PLATFORM.CMS), 'Content-Type': 'multipart/form-data' }
    })
    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/cms/property.ts', 'uploadOwnerLease')
    throw new Error(errorMessage)
  }
}

export async function deleteFloorPlanMedia(id: string): Promise<void> {
  try {
    const response = await api.delete(`/staff/properties/${id}/delete-floorplan-media`, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/cms/property.ts', 'deleteFloorPlanMedia')
    throw new Error(errorMessage)
  }
}
