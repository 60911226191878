import get from 'lodash/get'
import { PaginationList } from 'types'
import { IFilter } from 'types/query'
import { auth } from 'API'
import { PLATFORM } from 'API/constants'
import { handleError } from 'API/error'
import { ICity } from 'interfaces/city'
import { IMetro, IMetroPricing, IMetroWithRelations, IPricingTableSettings } from 'interfaces/metro'
import { api } from '..'

export async function getCMSMetroList(
  filter: IFilter<IMetroWithRelations>
): Promise<PaginationList<IMetroWithRelations>> {
  try {
    const response = await api.get(`/staff/metros?filter=${JSON.stringify(filter)}`, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/property', 'getCMSMetroList')
    return { results: [], totalCount: 0 }
  }
}

export async function getCMSMetroDetail(
  metroId: string,
  filter: IFilter<IMetroWithRelations>
): Promise<IMetroWithRelations> {
  try {
    const response = await api.get(`/staff/metros/${metroId}?filter=${JSON.stringify(filter)}`, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/property', 'getCMSMetroDetail')
    throw new Error(errorMessage)
  }
}

export async function createCMSMetro(metroData: IMetro): Promise<IMetro> {
  try {
    const response = await api.post(`/staff/metros`, metroData, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/cms/property.ts', 'createCMSMetro')
    throw new Error(errorMessage)
  }
}

export async function updateCMSMetro(id: string, metroData: Partial<IMetro>): Promise<IMetro> {
  try {
    const response = await api.patch(`/staff/metros/${id}`, metroData, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/cms/property.ts', 'updateCMSMetro')
    throw new Error(errorMessage)
  }
}

export async function getCMSMetroAvailableCities(): Promise<ICity[]> {
  try {
    const response = await api.get(`/staff/metros/cities`, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/cms/property.ts', 'getCMSMetroAvailableCities')
    throw new Error(errorMessage)
  }
}

export async function deleteMetroById(id: string): Promise<void> {
  try {
    const response = await api.delete(`/staff/metros/${id}`, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/cms/property.ts', 'deleteMetroById')
    throw new Error(errorMessage)
  }
}

export async function createMetroPricing(metroPricing: IMetroPricing): Promise<IMetroPricing> {
  try {
    const response = await api.post(`/staff/metro-pricings`, metroPricing, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/metro', 'createMetroPricing')
    return metroPricing
  }
}

export async function updateMetroPricing(metroPricing: IMetroPricing): Promise<IMetroPricing> {
  try {
    const response = await api.patch(`/staff/metro-pricings/${metroPricing.id}`, metroPricing, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/metro', 'updateMetroPricing')
    return metroPricing
  }
}

export async function getPricingTableSettings(): Promise<IPricingTableSettings[]> {
  try {
    const response = await api.get(`/staff/pricing-table-settings`, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/metro', 'getPricingTableSettings')
    return []
  }
}

export async function updatePricingTableSettings(
  pricingTableSettings: IPricingTableSettings
): Promise<IPricingTableSettings> {
  try {
    const response = await api.patch(`/staff/pricing-table-settings`, pricingTableSettings, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/metro', 'updatePricingTableSettings')
    return pricingTableSettings
  }
}
