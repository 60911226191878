import get from 'lodash/get'
import { PaginationList } from 'types'
import { AggregationPipeline } from 'types/mongo'
import { IFilter } from 'types/query'
import { api } from 'API'
import { auth } from 'API'
import { PLATFORM } from 'API/constants'
import { handleError } from 'API/error'
import { IRoomForm } from 'components/pages/CMS/PropertyManagement/RoomManagement/RoomDetail/constants'
import { IUnit, IUnitWithRelations } from 'interfaces/unit'
export async function getUnits(filter: IFilter<IUnit>): Promise<IUnitWithRelations[]> {
  try {
    const response = await api.get(`/staff/units?filter=${JSON.stringify(filter)}`, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/cms/unit', 'getUnits')
    return []
  }
}

export async function patchRoomDetail(id: string, data: IRoomForm): Promise<void> {
  try {
    const response = await api.patch(`/staff/units/${id}`, data, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/cms/unit', 'patchRoomDetail')
    throw new Error(errorMessage)
  }
}

export async function getRoomDetail(filter: IFilter<IUnitWithRelations>): Promise<IUnitWithRelations[]> {
  try {
    const response = await api.get(`/staff/units?filter=${JSON.stringify(filter)}`, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/cms/unit', 'getRoomDetail')
    throw new Error(errorMessage)
  }
}

export async function getCMSRooms(filter: IFilter<IUnit>): Promise<PaginationList<IUnit>> {
  try {
    const response = await api.get(`/staff/rooms?filter=${JSON.stringify(filter)}`, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/cms/unit', 'getCMSRooms')
    throw new Error(errorMessage)
  }
}

export async function deleteRoomById(id: string): Promise<void> {
  try {
    return api.delete(`/staff/rooms/${id}`, {
      headers: auth(PLATFORM.CMS)
    })
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/cms/unit', 'deleteRoomById')
    throw new Error(errorMessage)
  }
}

export async function getRoomAggregate(pipeline: AggregationPipeline = []): Promise<IUnitWithRelations[]> {
  try {
    const response = await api.post(`/staff/units/aggregate`, { pipeline, headers: auth(PLATFORM.CMS) })
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/cms/unit', 'getRoomAggregate')
    return []
  }
}

export async function reorderUnitMedia(sortedMediaIds: string[]): Promise<void> {
  try {
    await api.patch(`/staff/units/media-order`, sortedMediaIds, {
      headers: auth(PLATFORM.CMS)
    })
  } catch (error) {
    handleError(error as Error, 'API/unit', 'reorderUnitMedia')
  }
}
