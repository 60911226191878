import get from 'lodash/get'
import { IFilter } from 'types/query'
import { auth } from 'API'
import { PLATFORM } from 'API/constants'
import { handleError } from 'API/error'
import { IAmenitiesPackage } from 'interfaces/amenitiesPackage'
import { IHouseDefaultValue } from 'interfaces/houseDefaultValue'
import { IRoomDefaultValue } from 'interfaces/roomDefaultValue'
import { api } from '..'

export async function getCMSHouseDefaultValue(): Promise<IHouseDefaultValue[]> {
  try {
    const response = await api.get(`/staff/house-default-values`, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/cms/generalInformation.ts', 'getCMSHouseDefaultValue')
    return []
  }
}

export async function updateCMSHouseDefaultValue(
  houseDefaultValueData: IHouseDefaultValue
): Promise<IHouseDefaultValue> {
  try {
    const response = await api.patch(`/staff/house-default-values`, houseDefaultValueData, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/cms/generalInformation.ts', 'updateCMSHouseDefaultValue')
    return {}
  }
}

export async function getCMSRoomDefaultValue(filter: IFilter<IRoomDefaultValue>): Promise<IRoomDefaultValue[]> {
  try {
    const response = await api.get(`/staff/room-default-values?filter=${JSON.stringify(filter)}`, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/property', 'getRoomDefaultValue')
    return []
  }
}

export async function updateCMSRoomDefaultValue(roomDefaultValueData: IRoomDefaultValue): Promise<void> {
  try {
    const response = await api.patch(`/staff/room-default-values`, roomDefaultValueData, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/property', 'updateRoomDefaultValue')
    throw new Error(errorMessage)
  }
}

export async function getCMSAmenitiesPackage(filter: IFilter<IAmenitiesPackage>): Promise<IAmenitiesPackage[]> {
  try {
    const response = await api.get(`/staff/amenities-packages?filter=${JSON.stringify(filter)}`, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/property', 'getAmenitiesPakage')
    return []
  }
}

export async function updateCMSAmenitiesPackage(amenitiesPackageData: IAmenitiesPackage): Promise<void> {
  try {
    const response = await api.patch(`/staff/amenities-packages`, amenitiesPackageData, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/property', 'updateAmenitiesPakage')
    throw new Error(errorMessage)
  }
}

export async function upsertCMSHouseDefaultValue(houseDefaultValueData: IHouseDefaultValue): Promise<void> {
  try {
    await api.post(`/staff/house-default-values/upsert`, houseDefaultValueData, {
      headers: auth(PLATFORM.CMS)
    })
    return
  } catch (error) {
    handleError(error as Error, 'API/cms/generalInformation.ts', 'upsertCMSHouseDefaultValue')
  }
}

export async function upsertCMSRoomDefaultValue(roomDefaultValueData: IRoomDefaultValue): Promise<void> {
  try {
    await api.post(`/staff/room-default-values/upsert`, roomDefaultValueData, {
      headers: auth(PLATFORM.CMS)
    })
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/property', 'upsertRoomDefaultValue')
    throw new Error(errorMessage)
  }
}

export async function upsertCMSAmenitiesPackage(amenitiesPackage: IAmenitiesPackage): Promise<void> {
  try {
    await api.post(`/staff/amenities-packages/upsert`, amenitiesPackage, {
      headers: auth(PLATFORM.CMS)
    })
    return
  } catch (error) {
    handleError(error as Error, 'API/cms/generalInformation.ts', 'upsertCMSAmenitiesPackage')
  }
}
