import get from 'lodash/get'
import { IFilter } from 'types/query'
import { api, auth } from 'API'
import { PaginationList, PLATFORM } from 'API/constants'
import { handleError } from 'API/error'
import { IApplicant, IHouseApplication, IHouseApplicationWithRelations } from 'interfaces/application'

export async function getApplicants(filter: IFilter<IApplicant>): Promise<PaginationList<IApplicant>> {
  try {
    const response = await api.get(`/staff/applicants?filter=${JSON.stringify(filter)}`, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/cms/application.ts', 'getApplicants')
    return { results: [], totalCount: 0 }
  }
}

export async function getApplicantDetail(
  id: string,
  filter: IFilter<IHouseApplication>
): Promise<IHouseApplicationWithRelations> {
  try {
    const response = await api.get(`/staff/applicants/${id}?filter=${JSON.stringify(filter)}`, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/cms/application.ts', 'getApplicantDetail')
    return {} as IHouseApplicationWithRelations
  }
}

export async function updateApplicant(
  id: string,
  applicantData: Partial<IHouseApplication>
): Promise<IHouseApplication> {
  try {
    const response = await api.patch(`/staff/applicants/${id}`, applicantData, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/cms/application.ts', 'updateApplicant')
    return {} as IHouseApplication
  }
}

export async function deleteApplicant(id: string): Promise<void> {
  try {
    const response = await api.delete(`/staff/applicants/${id}`, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/cms/application.ts', 'deleteApplicant')
    throw new Error(errorMessage)
  }
}

export async function getContinueLinkToApplyingForApplicant(
  id: string,
  filter: IFilter<IHouseApplication>
): Promise<string> {
  try {
    const response = await api.get(`/staff/applicants/continue-link-to-apply/${id}?filter=${JSON.stringify(filter)}`, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/cms/application.ts', 'getContinueLinkToApplyingForApplicant')
    return ''
  }
}
