import get from 'lodash/get'
import { IFilter } from 'types/query'
import { api, auth } from 'API'
import { PaginationList, PLATFORM } from 'API/constants'
import { handleError } from 'API/error'
import { IOwner } from 'interfaces/owner'

export async function getOwners(filter: IFilter<IOwner>): Promise<PaginationList<IOwner>> {
  try {
    const response = await api.get(`/staff/owners?filter=${JSON.stringify(filter)}`, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/cms/owner.ts', 'getOwners')
    return { results: [], totalCount: 0 }
  }
}

export async function getOwnerDetail(id: string, filter: IFilter<IOwner>): Promise<IOwner> {
  try {
    const response = await api.get(`/staff/owners/${id}?filter=${JSON.stringify(filter)}`, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/cms/owner.ts', 'getOwnerDetail')
    throw new Error(errorMessage)
  }
}

export async function countOwners(where: Record<string, unknown> = {}): Promise<number> {
  try {
    const { data = 0 } = await api.get(`/staff/owners/count?where=${JSON.stringify(where)}`, {
      headers: auth(PLATFORM.CMS)
    })
    return Number(get(data, 'count', 0))
  } catch (error) {
    handleError(error as Error, 'API/cms/owner.ts', 'countOwners')
    return 0
  }
}

export async function createOwner(ownerData: IOwner): Promise<IOwner> {
  try {
    const response = await api.post(`/staff/owners`, ownerData, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/cms/owner.ts', 'createOwner')
    throw new Error(errorMessage)
  }
}

export async function updateOwner(id: string, ownerData: IOwner): Promise<IOwner> {
  try {
    const response = await api.patch(`/staff/owners/${id}`, ownerData, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/cms/owner.ts', 'updateOwner')
    throw new Error(errorMessage)
  }
}

export async function deleteOwnerById(id: string): Promise<void> {
  try {
    const response = await api.delete(`/staff/owners/${id}`, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/cms/owner.ts', 'deleteOwners')
    throw new Error(errorMessage)
  }
}
