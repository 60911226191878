import { makeAutoObservable } from 'mobx'
import { RootStore } from 'stores'
import { IFilter } from 'types/query'
import { deleteCMSListing, getCMSListingDetail, getCMSListings, updateCMSListing } from 'API/cms/listing'
import { handleError } from 'API/error'
import { IListing } from 'interfaces/listing'

class CMSListingStore {
  rootStore: RootStore
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  listingsList: IListing[] = []
  listingDetail?: IListing = undefined

  async fetchCMSListingsList(filter: IFilter<IListing> = {}): Promise<void> {
    try {
      const listingList: IListing[] = await getCMSListings(filter)
      this.listingsList = listingList
    } catch (error) {
      handleError(error as Error, 'stores/cms/cmsListingStore.ts', 'fetchCMSListingsList')
    }
  }

  async fetchCMSListingDetail(id: string, filter: IFilter<IListing> = {}): Promise<void> {
    try {
      const listingDetail: IListing = await getCMSListingDetail(id, filter)
      this.listingDetail = listingDetail
    } catch (error) {
      handleError(error as Error, 'stores/cms/cmsListingStore.ts', 'fetchCMSListingDetail')
    }
  }

  async updateCMSListing(id: string, listing: Partial<IListing>): Promise<void> {
    try {
      await updateCMSListing(id, listing)
    } catch (error) {
      handleError(error as Error, 'stores/cms/cmsListingStore.ts', 'updateCMSListing')
    }
  }

  async deleteCMSListing(id: string): Promise<void> {
    try {
      await deleteCMSListing(id)
    } catch (error) {
      handleError(error as Error, 'stores/cms/cmsListingStore.ts', 'deleteCMSListing')
    }
  }
}

export default CMSListingStore
