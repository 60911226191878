import { PaymentMethod } from '@stripe/stripe-js'
import { AxiosResponse } from 'axios'
import { api, auth } from 'API'
import { PLATFORM } from 'API/constants'
import { handleError } from 'API/error'
import { IStripeClient, IStripeCustomer, IPromotionCode } from 'interfaces/stripe'

const BASE_ENDPOINT = '/stripe'

export async function getStripeClientSecret(client?: IStripeClient): Promise<string> {
  try {
    const response: AxiosResponse = await api.post(`${BASE_ENDPOINT}/create-payment-intent`, client)
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/stripe.ts', 'getStripeClientSecret')
    return ''
  }
}

export async function getStripeCoupon(): Promise<IPromotionCode[]> {
  try {
    const response: AxiosResponse = await api.get(`${BASE_ENDPOINT}/get-coupons`)
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/stripe.ts', 'getStripeCoupon')
    return []
  }
}

export async function updatePaymentIntent(clientSecret: string, amount: number): Promise<IPromotionCode[]> {
  try {
    const response: AxiosResponse = await api.post(`${BASE_ENDPOINT}/update-payment-intent`, {
      clientSecret,
      amount
    })
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/stripe.ts', 'getStripeCoupon')
    return []
  }
}

export async function getPaymentMethodsOfUser(): Promise<PaymentMethod[]> {
  try {
    const response: AxiosResponse = await api.get(`${BASE_ENDPOINT}/get-payment-methods-of-user`, {
      headers: auth(PLATFORM.WEBSITE)
    })

    return response.data
  } catch (error) {
    handleError(error as Error, 'API/stripe.ts', 'getPaymentMethodsOfUser')
    return []
  }
}

export async function getMyCustomer(): Promise<IStripeCustomer> {
  try {
    const response: AxiosResponse = await api.get(`${BASE_ENDPOINT}/get-customer-of-user`, {
      headers: auth(PLATFORM.WEBSITE)
    })

    return response.data
  } catch (error) {
    handleError(error as Error, 'API/stripe.ts', 'getMyCustomer')
    return {} as IStripeCustomer
  }
}

export async function createPaymentMethodForUser(
  paymentMethodId: string,
  isDefaultPaymentMethod: boolean
): Promise<PaymentMethod> {
  try {
    const response: AxiosResponse = await api.post(
      `${BASE_ENDPOINT}/create-payment-method-for-user`,
      { paymentMethodId, isDefaultPaymentMethod },
      {
        headers: auth(PLATFORM.WEBSITE)
      }
    )

    return response.data
  } catch (error) {
    handleError(error as Error, 'API/stripe.ts', 'createPaymentMethodForUser')
    throw error
  }
}

export async function setDefaultPaymentMethod(paymentMethodId: string): Promise<void> {
  try {
    await api.post(
      `${BASE_ENDPOINT}/set-default-payment-method-for-user`,
      { paymentMethodId },
      {
        headers: auth(PLATFORM.WEBSITE)
      }
    )
  } catch (error) {
    handleError(error as Error, 'API/stripe.ts', 'setDefaultPaymentMethod')
    throw error
  }
}

export async function detachPaymentMethod(paymentMethodId: string): Promise<void> {
  try {
    await api.post(
      `${BASE_ENDPOINT}/detach-payment-method-for-user`,
      { paymentMethodId },
      {
        headers: auth(PLATFORM.WEBSITE)
      }
    )
  } catch (error) {
    handleError(error as Error, 'API/stripe.ts', 'detachPaymentMethod')
    throw error
  }
}
