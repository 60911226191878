import { makeAutoObservable } from 'mobx'
import { RootStore } from 'stores'
import { IFilter } from 'types/query'
import { handleError } from 'API/error'
import { getMetros } from 'API/metro'
import { IMetro } from 'interfaces/metro'

class MetroStore {
  rootStore: RootStore
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  metroList: IMetro[] = []

  async fetchMetroList(filter: IFilter<IMetro> = {}) {
    try {
      this.metroList = await getMetros(filter)
    } catch (error) {
      handleError(error as Error, 'stores/metroStore.ts', 'fetchMetroList')
    }
  }

  setMetroList(metroList: IMetro[]) {
    this.metroList = metroList
  }
}

export default MetroStore
