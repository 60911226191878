import { AggregationPipeline } from 'types/mongo'
import { IFilter } from 'types/query'
import { api } from 'API'
import { handleError } from 'API/error'
import { IUnit, IUnitWithRelations } from 'interfaces/unit'
export async function getUnits(filter: IFilter<IUnit>): Promise<IUnitWithRelations[]> {
  try {
    const response = await api.get(`/units?filter=${JSON.stringify(filter)}`)
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/unit', 'getUnits')
    return []
  }
}

export async function getRoomAggregate(pipeline: AggregationPipeline = []): Promise<IUnitWithRelations[]> {
  try {
    const response = await api.post(`/units/aggregate`, { pipeline })
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/unit', 'getRoomAggregate')
    return []
  }
}

export async function getUnitDetailByRoomId(roomId: number): Promise<IUnitWithRelations> {
  try {
    const response = await api.get(`/units/detail/${roomId}`)
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/unit', 'getUnitDetailById')
    return {} as IUnitWithRelations
  }
}
