import { AxiosResponse } from 'axios'
import get from 'lodash/get'
import { api } from 'API'
import { handleError } from 'API/error'
import { IAddressRequest, IAddressSuggestion } from 'interfaces/application'

const BASE_PATH: string = '/customer/house-applications'

export async function getAddressData(addressData: IAddressRequest): Promise<IAddressSuggestion[]> {
  try {
    const url: string = `${BASE_PATH}/autofill-address`
    const response: AxiosResponse = await api.post(url, addressData)
    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/houseApplication/mapbox.ts', 'getAddressData')
    throw new Error(errorMessage)
  }
}
