import remove from 'lodash/remove'
import { makeAutoObservable } from 'mobx'
import { RootStore } from 'stores'
import { deleteFaq, getFaqs, updateFaq } from 'API/cms/faq'
import { handleError } from 'API/error'
import { IFaq } from 'interfaces/faq'
import { checkValidArray, getValidArray } from 'utils/common'

class FaqStore {
  rootStore: RootStore
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  cmsFaqList: IFaq[] = []
  deletedFaqIds: string[] = []

  async fetchCMSFaqList(): Promise<void> {
    try {
      const faqList: IFaq[] = await getFaqs()
      this.cmsFaqList = faqList
      this.deletedFaqIds = []
    } catch (error) {
      handleError(error as Error, 'stores/cms/cmsFaqStore.ts', 'fetchCMSFaqList')
    }
  }

  async updateCMSFaq(): Promise<void> {
    try {
      await updateFaq(this.cmsFaqList)
      if (checkValidArray(this.deletedFaqIds)) {
        await Promise.all(
          this.deletedFaqIds.map(async (id: string) => {
            await deleteFaq(id)
          })
        )
      }
      this.fetchCMSFaqList()
    } catch (error) {
      handleError(error as Error, 'stores/cms/cmsFaqStore.ts', 'updateCMSFaq')
    }
  }

  create(newFaq: IFaq): void {
    this.cmsFaqList.push(newFaq)
  }

  findByOrder(order: number): IFaq | undefined {
    return getValidArray(this.cmsFaqList).find((faq: IFaq) => faq?.order === order)
  }

  updateByOrder(updatedFaq: IFaq, order?: number): void {
    const faqs: IFaq[] = getValidArray(this.cmsFaqList).map((faq: IFaq) => {
      return faq?.order === order ? updatedFaq : faq
    })
    this.cmsFaqList = faqs
  }

  deleteByOrder(currentFaq: IFaq): void {
    remove(this.cmsFaqList, (faq: IFaq) => faq?.order === currentFaq?.order)
    if (currentFaq?.id) {
      this.deletedFaqIds.push(currentFaq?.id)
    }
    const newFaqs: IFaq[] = getValidArray(this.cmsFaqList).map((faq: IFaq) => {
      if (faq?.order && currentFaq?.order && faq?.order > currentFaq?.order) {
        return { ...faq, order: faq?.order - 1 }
      }
      return faq
    })
    this.cmsFaqList = newFaqs
  }
}

export default FaqStore
