import { makeAutoObservable } from 'mobx'
import { RootStore } from 'stores'
import { IFilter } from 'types/query'
import { getBuildiumOwners } from 'API/cms/buildiumOwner'
import { handleError } from 'API/error'
import { getOwnerProperties } from 'API/ownerPortal/owner'
import { IBuildiumGLTransactionWithAmount } from 'interfaces/glTransaction'
import { IBuildiumOwner } from 'interfaces/owner'
import { IProperty } from 'interfaces/property'

class OwnerStore {
  rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  buildiumOwners: IBuildiumOwner[] = []

  incomeTransactions: IBuildiumGLTransactionWithAmount[] = []

  expenseTransactions: IBuildiumGLTransactionWithAmount[] = []

  properties: IProperty[] = []

  async fetchBuildiumOwners(filter: IFilter<IBuildiumOwner> = {}): Promise<void> {
    try {
      this.buildiumOwners = await getBuildiumOwners(filter)
    } catch (error) {
      handleError(error as Error, 'stores/ownerStore.ts', 'fetchBuildiumOwners')
    }
  }

  async getMyHouseList(filter: IFilter<IProperty> = {}): Promise<void> {
    this.rootStore.spinnerStore.showLoading()
    try {
      const result = await getOwnerProperties(filter)
      this.properties = result
    } catch (error) {
      handleError(error as Error, 'stores/ownerStore.ts', 'getMyUser')
    }
    this.rootStore.spinnerStore.hideLoading()
  }
}

export default OwnerStore
