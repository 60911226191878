import { makeAutoObservable } from 'mobx'
import { RootStore } from 'stores'
import { IFilter } from 'types/query'
import { handleError } from 'API/error'
import { getUserBackgroundInformation, patchUserBackgroundInformation } from 'API/userBackgroundInformation'
import { IUserBackgroundInformation } from 'interfaces/userBackgroundInformation'

class UserBackgroundInformationStore {
  rootStore: RootStore
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  userBackgroundInformation: IUserBackgroundInformation = {} as IUserBackgroundInformation

  async fetchUserBackgroundInformation(
    userId: string,
    filter: IFilter<IUserBackgroundInformation> = {}
  ): Promise<void> {
    try {
      this.userBackgroundInformation = await getUserBackgroundInformation(filter, userId)
    } catch (error) {
      handleError(error as Error, 'stores/userBackgroundInformationStore.ts', 'fetchUserBackgroundInformation')
    }
  }

  async updateUserBackgroundInformation(
    userId: string,
    userBackgroundInformation: IUserBackgroundInformation
  ): Promise<void> {
    try {
      this.userBackgroundInformation = await patchUserBackgroundInformation(userId, userBackgroundInformation)
    } catch (error) {
      handleError(error as Error, 'stores/userBackgroundInformationStore.ts', 'patchUserBackgroundInformation')
    }
  }
}

export default UserBackgroundInformationStore
