import { makeAutoObservable } from 'mobx'
import { RootStore } from 'stores'
import { IFilter } from 'types/query'
import { getCMSAmenitiesPackage, upsertCMSAmenitiesPackage } from 'API/cms/generalInformation'
import { handleError } from 'API/error'
import { IAmenitiesPackage } from 'interfaces/amenitiesPackage'

class AmenitiesPackageStore {
  rootStore: RootStore
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  amenitiesPackage: IAmenitiesPackage = {}

  async fetchAmenitiesPackage(filter: IFilter<IAmenitiesPackage> = {}): Promise<void> {
    try {
      const amenitiesPackage: IAmenitiesPackage[] = await getCMSAmenitiesPackage(filter)
      this.amenitiesPackage = amenitiesPackage[0]
    } catch (error) {
      handleError(error as Error, 'stores/cms/cmsGeneralInformationStore.ts', 'fetchAmenitiesPackage')
    }
  }

  async updateAmenitiesPackage(amenitiesPackageData: IAmenitiesPackage): Promise<void> {
    await upsertCMSAmenitiesPackage(amenitiesPackageData)
  }
}

export default AmenitiesPackageStore
