import { makeAutoObservable } from 'mobx'
import { RootStore } from 'stores'
import { getLeases, getLeaseDetail, uploadTenantLeaseFile } from 'API/cms/lease'
import { handleError } from 'API/error'
import { IRoom } from 'interfaces/lease'

class StaffLeaseStore {
  rootStore: RootStore
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  leasesList: IRoom[] = []
  leaseDetail?: IRoom = undefined
  totalCount: number = 0

  async fetchLeasesList(houseId: number, skip?: number, limit?: number): Promise<void> {
    try {
      const { results: leaseList, totalCount } = await getLeases(houseId, skip, limit)
      this.leasesList = leaseList
      this.totalCount = totalCount
    } catch (error) {
      handleError(error as Error, 'stores/cms/cmsLeaseStore.ts', 'fetchLeasesList')
    }
  }

  async fetchLeaseDetail(id: string, filter = {}): Promise<void> {
    const lease = await getLeaseDetail(id, filter)
    this.leaseDetail = lease
  }

  // *TODO: Intergrate new uploadTenantLease file
  async postTenantLeaseFile(id: string, file: File): Promise<string> {
    const result = await uploadTenantLeaseFile(id, file)
    return result ?? ''
  }
}

export default StaffLeaseStore
