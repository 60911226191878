import { get } from 'lodash'
import { IFilter } from 'types/query'
import { auth } from 'API'
import { PLATFORM } from 'API/constants'
import { handleError } from 'API/error'
import { IOwnerPaymentTracker } from 'interfaces/ownerPaymentTracker'
import { api } from '..'

export async function getOwnerPaymentTracker(
  id: string,
  filter: IFilter<IOwnerPaymentTracker>
): Promise<IOwnerPaymentTracker> {
  try {
    const response = await api.get(`/staff/owner-payment-trackers/${id}?filter=${JSON.stringify(filter)}`, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/cms/ownerPaymentTracker.ts', 'getOwnerPaymentTracker')
    throw new Error(errorMessage)
  }
}

export async function getOwnerPaymentTrackerList(
  filter: IFilter<IOwnerPaymentTracker>
): Promise<IOwnerPaymentTracker[]> {
  try {
    const response = await api.get(`/staff/owner-payment-trackers?filter=${JSON.stringify(filter)}`, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/cms/ownerPaymentTracker.ts', 'getOwnerPaymentTracker')
    return []
  }
}

export async function patchOwnerPaymentTracker(
  id: string,
  ownerPaymentTrackerData: IOwnerPaymentTracker
): Promise<IOwnerPaymentTracker[]> {
  try {
    const response = await api.patch(`/staff/owner-payment-trackers/${id}`, ownerPaymentTrackerData, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/cms/ownerPaymentTracker.ts', 'patchOwnerPaymentTracker')
    return []
  }
}

export async function postOwnerPaymentTracker(
  ownerPaymentTrackerData: IOwnerPaymentTracker
): Promise<IOwnerPaymentTracker[]> {
  try {
    const response = await api.post(`/staff/owner-payment-trackers`, ownerPaymentTrackerData, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/cms/ownerPaymentTracker.ts', 'postOwnerPaymentTracker')
    return []
  }
}

export async function countOwnerPaymentTracker(filter: IFilter<IOwnerPaymentTracker> = {}): Promise<number> {
  try {
    const { data = 0 } = await api.get(`/staff/owner-payment-trackers/count?filter=${JSON.stringify(filter)}}`, {
      headers: auth(PLATFORM.CMS)
    })
    return Number(get(data, 'count', 0))
  } catch (error) {
    return 0
    handleError(error as Error, 'API/cms/ownerPaymentTracker.ts', 'countOwnerPaymentTracker')
  }
}
