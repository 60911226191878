import { api, auth } from 'API'
import { PLATFORM } from 'API/constants'
import { handleError } from 'API/error'
import { ILease } from 'interfaces/lease'

interface IResponseType {
  results: ILease[]
  totalCount: number
}

export async function getLeases(skip: number, limit: number): Promise<IResponseType> {
  try {
    const response = await api.get(`/owner/leases?skip=${skip}&limit=${limit}`, {
      headers: auth(PLATFORM.OWNER)
    })
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/ownerPortal/lease', 'getLeases')
    return { results: [], totalCount: 0 }
  }
}
