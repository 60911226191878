import cmsRoutes from './cms'
import ownerPortalRoutes from './ownerPortal'

const routes = {
  home: {
    value: '/'
  },
  about: {
    value: '/about'
  },
  notfoundpage: {
    value: '/404'
  },
  faq: {
    value: '/faq'
  },
  detail: {
    value: (houseId: number) => `/house-detail/${houseId}`,
    room: {
      value: (houseId: number, roomId: number) => `/house-detail/${houseId}/room-detail/${roomId}`
    }
  },
  myProfile: {
    value: '/my-profile'
  },
  ownAHomeRoom: {
    value: '/own-home-room'
  },
  ...ownerPortalRoutes,
  ...cmsRoutes
}

export default routes
