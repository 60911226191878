import get from 'lodash/get'
import { IFilter } from 'types/query'
import { auth } from 'API'
import { PLATFORM } from 'API/constants'
import { handleError } from 'API/error'
import { IListing } from 'interfaces/listing'
import { api } from '..'

export async function getCMSListings(filter: IFilter<IListing>): Promise<IListing[]> {
  try {
    const response = await api.get(`/staff/listings?filter=${JSON.stringify(filter)}`, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/cms/listing', 'getCMSListings')
    return []
  }
}

export async function getCMSListingDetail(listingId: string, filter: IFilter<IListing>): Promise<IListing> {
  try {
    const response = await api.get(`/staff/listings/${listingId}?filter=${JSON.stringify(filter)}`, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/cms/listing', 'getCMSListingDetail')
    throw new Error(errorMessage)
  }
}

export async function updateCMSListing(id: string, listingData: Partial<IListing>): Promise<IListing> {
  try {
    const response = await api.patch(`/staff/listings/${id}`, listingData, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/cms/listing', 'updateCMSListing')
    throw new Error(errorMessage)
  }
}

export async function deleteCMSListing(id: string): Promise<void> {
  try {
    const response = await api.delete(`/staff/listings/${id}`, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/cms/listing', 'deleteCMSListing')
    throw new Error(errorMessage)
  }
}
