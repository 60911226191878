import { makeAutoObservable } from 'mobx'
import router from 'next/router'
import { RootStore } from 'stores'
import { PaginationList } from 'types'
import { AggregationPipeline } from 'types/mongo'
import { IFilter } from 'types/query'
import { getCMSHouseList } from 'API/cms/property'
import { getCMSRooms, getRoomDetail, patchRoomDetail } from 'API/cms/unit'
import { handleError } from 'API/error'
import { getRoomAggregate, getUnits } from 'API/unit'
import { cmsPropertyManagementRoute } from 'components/pages/CMS/constant'
import { IRoomForm } from 'components/pages/CMS/PropertyManagement/RoomManagement/RoomDetail/constants'
import { ITenant } from 'interfaces/listing'
import { IUnit, IUnitWithRelations } from 'interfaces/unit'
import { checkValidArray } from 'utils/common'
import { IProperty } from './../../interfaces/property/index'

class UnitStore {
  rootStore: RootStore
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  cmsRoomList: PaginationList<IUnit> = {
    results: [],
    totalCount: 0
  }
  roomList: IUnitWithRelations[] = []
  roomDetail: IUnitWithRelations = {} as IUnitWithRelations
  countRoomList(): number {
    return this.roomList.length
  }
  currentTenant?: ITenant = undefined

  setCurrentTenant(currentTenant: ITenant): void {
    this.currentTenant = currentTenant
  }

  resetCurrentTenant(): void {
    this.currentTenant = undefined
  }

  async setRoomList(roomList: IUnitWithRelations[]): Promise<void> {
    try {
      this.roomList = roomList
    } catch (error) {
      handleError(error as Error, 'stores/unitStore.ts', 'setRoomList')
    }
  }

  async fetchRoomList(filter: IFilter<IUnit> = {}) {
    try {
      this.roomList = await getUnits(filter)
    } catch (error) {
      handleError(error as Error, 'stores/unitStore.ts', 'fetchRoomList')
    }
  }

  async fetchRoomDetail(filter: IFilter<IUnitWithRelations> = {}): Promise<void> {
    try {
      const rooms: IUnitWithRelations[] = await getRoomDetail(filter)
      if (checkValidArray(rooms) && rooms?.length > 0) {
        const roomData: IUnitWithRelations = rooms[0]
        const houses: PaginationList<IProperty> = await getCMSHouseList({
          where: {
            buildiumPropertyId: rooms[0].buildiumPropertyId
          }
        })
        if (Array.isArray(houses.results) && houses.results.length > 0) {
          roomData.address = houses.results[0].address
        }
        this.roomDetail = roomData
      } else {
        router.push(cmsPropertyManagementRoute.room)
      }
    } catch (error) {
      handleError(error as Error, 'stores/unitStore.ts', 'fetchRoomDetail')
    }
  }

  async updateRoomDetail(id: string, data: IRoomForm): Promise<void> {
    await patchRoomDetail(id, data)
  }

  async fetchCMSRoomList(filter: IFilter<IUnit> = {}) {
    try {
      const roomList: PaginationList<IUnit> = await getCMSRooms(filter)
      this.cmsRoomList = roomList
    } catch (error) {
      handleError(error as Error, 'stores/unitStore.ts', 'fetchCMSRoomList')
    }
  }

  async fetchRoomDetailAggregate(pipeline: AggregationPipeline = []) {
    try {
      const rooms: IUnitWithRelations[] = await getRoomAggregate(pipeline)
      this.roomDetail = checkValidArray(rooms) && rooms?.length > 0 ? rooms[0] : ({} as IUnitWithRelations)
    } catch (error) {
      handleError(error as Error, 'stores/unitStore.ts', 'fetchRoomDetailAggregate')
    }
  }
}

export default UnitStore
