import { makeAutoObservable } from 'mobx'
import { RootStore } from 'stores'
import { PaginationList } from 'types'
import { IFilter } from 'types/query'
import { getOwners, getOwnerDetail } from 'API/cms/owner'
import { handleError } from 'API/error'
import { IOwner } from 'interfaces/owner'

class CMSOwnerStore {
  rootStore: RootStore
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  cmsOwnerList: PaginationList<IOwner> = {
    results: [],
    totalCount: 0
  }
  ownerDetail?: IOwner = undefined

  async fetchOwners(filter = {}): Promise<void> {
    try {
      const owners: PaginationList<IOwner> = await getOwners(filter)
      this.cmsOwnerList = owners
    } catch (error) {
      handleError(error as Error, 'stores/cmsOwnerStore', 'fetchOwners')
    }
  }

  async fetchOwnerDetail(id: string, filter: IFilter<IOwner> = {}): Promise<void> {
    try {
      this.ownerDetail = await getOwnerDetail(id, filter)
    } catch (error) {
      handleError(error as Error, 'stores/cmsOwnerStore', 'fetchOwnerDetail')
    }
  }
}

export default CMSOwnerStore
