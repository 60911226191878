import get from 'lodash/get'
import { makeAutoObservable } from 'mobx'
import { RootStore } from 'stores'
import {
  countBuildiumOwners,
  getBuildiumOwnerDetail,
  getBuildiumOwners,
  createBuildiumOwner as createBuildiumOwnerAPI,
  updateBuildiumOwner as updateBuildiumOwnerAPI
} from 'API/cms/buildiumOwner'
import { IBuildiumOwner } from 'interfaces/owner'

class CMSBuildiumOwnerStore {
  rootStore: unknown

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }
  ownerList: IBuildiumOwner[] = []

  ownersLength: number = 0

  ownerDetail?: IBuildiumOwner = undefined

  async fetchBuildiumOwners(filter = {}) {
    const [owners, ownersLength] = await Promise.all([
      getBuildiumOwners(filter),
      countBuildiumOwners(get(filter, 'where', {}))
    ])
    this.ownerList = owners
    this.ownersLength = ownersLength
  }

  async fetchBuildiumOwnerDetail(id: string, filter = {}) {
    const owner = await getBuildiumOwnerDetail(id, filter)
    this.ownerDetail = owner
  }

  async createBuildiumOwner(owner: IBuildiumOwner): Promise<void> {
    const newBuildiumOwner = await createBuildiumOwnerAPI(owner)
    this.ownerDetail = newBuildiumOwner as IBuildiumOwner
  }

  async updateBuildiumOwner(id: string, owner: IBuildiumOwner): Promise<void> {
    await updateBuildiumOwnerAPI(id, owner)
  }

  resetStore() {
    this.ownerDetail = undefined
  }
}

export default CMSBuildiumOwnerStore
