const cmsRoutes = {
  cms: {
    value: '/cms',
    login: {
      value: '/cms/login'
    },
    forgotPassword: {
      value: '/cms/forgot-password'
    },
    resetPassword: {
      value: (resetPasswordToken: string) => `/cms/reset-password/${resetPasswordToken}`
    },
    accountSettings: {
      value: '/cms/account-settings'
    },
    generalSettings: {
      value: '/cms/general-settings',
      metro: {
        value: (metroName: string) => `/cms/general-settings/metro/${metroName}`,
        addNew: {
          value: '/cms/general-settings/metro/add-new'
        }
      }
    },
    propertyManagement: {
      value: '/cms/property-management',
      room: {
        value: (roomId: string) => `/cms/property-management/room/${roomId}`
      },
      house: {
        value: (houseId: string) => `/cms/property-management/house/${houseId}`
      },
      generalInformation: {
        value: '/cms/property-management/general-information'
      }
    },
    ownerPortalInformation: {
      value: '/cms/owner-portal-information',
      owner: {
        value: '/cms/owner-portal-information/owner',
        detail: {
          value: (ownerId: string) => `/cms/owner-portal-information/owner/${ownerId}`
        }
      },
      financial: {
        value: '/cms/owner-portal-information/financials-uploader',
        guideLine: {
          value: '/cms/owner-portal-information/financials-uploader/guide-line'
        },
        upload: {
          value: '/cms/owner-portal-information/financials-uploader/upload'
        },
        detail: {
          value: (financialId: string) => `/cms/owner-portal-information/financials-uploader/${financialId}`
        }
      },
      lease: {
        value: '/cms/owner-portal-information/lease',
        detail: {
          value: (houseId: string) => `/cms/owner-portal-information/lease/${houseId}`
        }
      },
      invoice: {
        value: '/cms/owner-portal-information/invoice',
        detail: {
          value: (invoiceId: string) => `/cms/owner-portal-information/invoice/${invoiceId}`
        }
      },
      payment: {
        value: '/cms/owner-portal-information/payment',
        detail: {
          value: (paymentId: string) => `/cms/owner-portal-information/payment/${paymentId}`
        },
        addNew: {
          value: '/cms/owner-portal-information/payment/add-new'
        }
      }
    },
    accountManagement: {
      value: '/cms/account-management',
      detail: {
        value: (accountId: string) => `/cms/account-management/account/${accountId}`
      }
    },
    applicationManagement: {
      value: '/cms/application-management',
      applicant: {
        value: '/cms/application-management/applicant',
        detail: {
          value: (applicationId: string) => `/cms/application-management/applicant/detail/${applicationId}`
        }
      },
      policySetting: {
        value: '/cms/application-management/policy-setting'
      }
    }
  }
}

export default cmsRoutes
