import get from 'lodash/get'
import { IFilter } from 'types/query'
import { api, auth } from 'API'
import { PLATFORM } from 'API/constants'
import { handleError } from 'API/error'
import { IRoom } from 'interfaces/lease'

interface IResponseType {
  results: IRoom[]
  totalCount: number
}

export async function getLeases(houseId: number, skip?: number, limit?: number): Promise<IResponseType> {
  try {
    const skipQuery = skip ? `&skip=${skip}` : ''
    const limitQuery = limit ? `&limit=${limit}` : ''
    const response = await api.get(`/staff/leases?house-id=${houseId}${skipQuery}${limitQuery}`, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/cms/lease.ts', 'getLeases')
    return { results: [], totalCount: 0 }
  }
}

export async function getLeaseDetail(id: string, filter: IFilter<IRoom>): Promise<IRoom> {
  try {
    const response = await api.get(`/staff/leases/${id}?filter=${JSON.stringify(filter)}`, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/cms/lease.ts', 'getLeaseDetail')
    return {}
  }
}

// *TODO: Intergrate New uploadTenantLease File
export async function uploadTenantLeaseFile(id: string, file: File): Promise<string> {
  const body = new FormData()
  body.append('file', file, file.name)
  try {
    const response = await api.patch(`/staff/leases/${id}/upload-tenant-lease`, body, {
      headers: { ...auth(PLATFORM.CMS), 'Content-Type': 'multipart/form-data' }
    })
    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/cms/property.ts', 'uploadOwnerLease')
    throw new Error(errorMessage)
  }
}
