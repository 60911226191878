import { AxiosResponse } from 'axios'
import get from 'lodash/get'
import { AggregationPipeline } from 'types/mongo'
import { IFilter, Where } from 'types/query'
import { api } from 'API'
import { handleError } from 'API/error'
import { IApplicationFormValues, ILeaseAgreementPayload, ISigningEnvelopePayload } from 'interfaces/application'
import { IListing, IListingFilter } from 'interfaces/listing'
import { IProperty, IPropertyDetailPayload, IPropertyWithRelations, IPropertyWithTenants } from 'interfaces/property'

export async function upsertHousesApplication(
  houseApplication: IApplicationFormValues,
  initialBuildiumUnitId: number
): Promise<IApplicationFormValues> {
  try {
    const url: string = `/customer/house-applications/upsert/${initialBuildiumUnitId}`
    const response: AxiosResponse = await api.patch(url, houseApplication)
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/listing', 'upsertHousesApplication')
    return {}
  }
}

export async function getSigningLeaseAgreement(
  agreementData: ILeaseAgreementPayload
): Promise<ISigningEnvelopePayload> {
  try {
    const response = await api.post(`/customer/house-applications/signing-lease-agreement`, agreementData)
    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/listing', 'upsertHousesApplication')
    throw new Error(errorMessage)
  }
}

export async function aggregateProperties(pipeline: AggregationPipeline): Promise<IProperty[]> {
  try {
    const response = await api.post(`/customer/properties/aggregate`, { pipeline })
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/listing', 'aggregateProperties')
    return []
  }
}

export async function getHouses(filter: IFilter<IListing>): Promise<IListing[]> {
  try {
    const response = await api.get(`/listings?filter=${JSON.stringify(filter)}`)
    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/listing', 'getHouses')
    throw new Error(errorMessage)
  }
}

export async function getHousesAggregate(pipeline: AggregationPipeline): Promise<IListing[]> {
  try {
    const response = await api.post(`/listings/aggregate`, { pipeline })
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/listing', 'getHousesAggregate')
    return []
  }
}

export async function getHouseDetailByHouseId(
  houseDetailData: IPropertyDetailPayload
): Promise<IPropertyWithRelations> {
  try {
    const response: AxiosResponse = await api.post(`/customer/properties/detail`, houseDetailData)
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/listing', 'getHouseDetailByHouseId')
    return {} as IPropertyWithRelations
  }
}

export async function countHousesAggregate(pipeline: AggregationPipeline): Promise<number> {
  try {
    const response = await api.post(`/listings/aggregate/count`, { pipeline })
    return Number(get(response.data, 'totalCount', 0))
  } catch (error) {
    handleError(error as Error, 'API/listing', 'countHousesAggregate')
    return 0
  }
}

export async function countHouses(where: Where<IListing>): Promise<number> {
  try {
    const { data = 0 } = await api.get(`/listings/count?where=${JSON.stringify(where)}`)
    return Number(get(data, 'count', 0))
  } catch (error) {
    handleError(error as Error, 'API/listing', 'countHouses')
    return 0
  }
}

export async function getHouseListByFilter(filter: IListingFilter): Promise<IProperty[]> {
  try {
    const response: AxiosResponse = await api.post(`/customer/properties`, filter)
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/listing', 'getHouseListByFilter')
    return []
  }
}

export async function getHouseDetail(id: string, filter: IFilter<IListing>): Promise<IListing> {
  try {
    const response = await api.get(`/listings/${id}?filter=${JSON.stringify(filter)}`)
    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/listing', 'errorMessage')
    throw new Error(errorMessage)
  }
}

export async function deleteHouseById(id: string): Promise<void> {
  try {
    await api.delete(`/houses/${id}`)
  } catch (error) {
    handleError(error as Error, 'API/listing', 'deleteHouseById')
  }
}

export async function aggregatePropertiesWithTenants(pipeline: AggregationPipeline): Promise<IPropertyWithTenants[]> {
  try {
    const response = await api.post(`/customer/properties/aggregate`, { pipeline })
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/listing', 'aggregatePropertiesWithTenants')
    return []
  }
}
