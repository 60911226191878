import { makeAutoObservable } from 'mobx'
import { RootStore } from 'stores'
import { handleError } from 'API/error'
import { getLeases } from 'API/ownerPortal/lease'
import { ILease } from 'interfaces/lease'

class LeaseStore {
  rootStore: RootStore
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  houseList: ILease[] = []
  totalCount: number = 0

  async fetchHouseList(skip: number, limit: number) {
    try {
      const { results: houseList, totalCount } = await getLeases(skip, limit)
      this.houseList = houseList
      this.totalCount = totalCount
    } catch (error) {
      handleError(error as Error, 'stores/ownerPortal/ownerLeaseStore.ts', 'fetchHouseList')
    }
  }
}

export default LeaseStore
