import { IFinancialFile } from 'interfaces/financialUploader'

export enum EPropertyPayoutType {
  GUARANTEED = 'guaranteed',
  REVENUE_SHARE = 'revenue-share'
}

export interface IPropertyFinancial {
  id: string
  propertyName: string
  buildiumPropertyId: number
  financialFiles: IFinancialFile[]
  years: number[]
}
