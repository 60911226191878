// GLOBAL
export const globalBackground = '#E5E5E5'

// BACKGROUND
export const backgroundPrimary = '#242E2E'
export const backgroundSecondary = '#81E6D9'
export const backgroundButton = '#319795'
export const backgroundButtonHover = '#2C7A7B'
export const backgroundGrey200 = '#E2E8F0'
export const backgroundGrey500 = 'rgba(255, 255, 255, 0.49)'
export const backgroundGrey600 = 'rgba(0, 0, 0, 0.06)'
export const backgroundWhite500 = 'rgba(255, 255, 255, 0.36)'
export const backgroundWhite600 = '#fefefe'
export const backgroundBlue50 = '#EDF2F7'
//*INFO: teal.500 with opacity: 0.15
export const backGroundTeal500WithOpacity = '#E0EFEF'

// TEXT
export const textPrimary = '#1A202C'
export const textSecondary = '#319795'
export const textGrey200 = '#F7FAFC'
export const textGrey500 = '#718096'
export const textGrey600 = '#4A5568'
export const textGrey700 = '#2D3748'
export const textGrey800 = '#1A202C'

// FOCUS_BORDER_COLOR
export const focusBorderColorPrimary = 'rgba(49, 151, 149, 0.6)'
export const focusBorderColorSecondary = 'rgba(49, 151, 149, 1)'

// OTHER
export const boxShadow = '0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)'

// BREAKPOINTS
// INFO: Our breakpoints (576px, 744px, 1200px, 1440px)
export const breakPointValues = {
  base: '0px',
  sm: '575.9px',
  md: '743.9px',
  lg: '1199.9px',
  xl: '1439.9px',
  xxl: '1739.9px'
}

export enum EBreakPointValues {
  BASE = '0px',
  SM = '576px',
  MD = '744px',
  LG = '1200px',
  XL = '1440px',
  XXL = '1740px'
}

export const maxTabletWidth = 1199.9
export const maxMobileWidth = 743.9
export const maxSmallMobileWidth = 375.9
