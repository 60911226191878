import { makeAutoObservable } from 'mobx'
import { RootStore } from 'stores'
import { PaginationList } from 'types'
import { IFilter } from 'types/query'
import {
  getCMSHouseDetail,
  getCMSHouseDetailByBuildiumPropertyId,
  getCMSHouses,
  updateCMSHouse,
  uploadOwnerLeaseFile
} from 'API/cms/property'
import { handleError } from 'API/error'
import { IProperty, IPropertyWithRelations } from 'interfaces/property'

class HouseStore {
  rootStore: RootStore
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  cmsHouseList: PaginationList<IProperty> = {
    results: [],
    totalCount: 0
  }
  houseDetail: IPropertyWithRelations = {} as IPropertyWithRelations

  async fetchCMSHouseList(filter: IFilter<IProperty> = {}): Promise<void> {
    try {
      const houseList: PaginationList<IProperty> = await getCMSHouses(filter)
      this.cmsHouseList = houseList
    } catch (error) {
      handleError(error as Error, 'stores/cms/cmsHouseStore.ts', 'fetchCMSHouseList')
    }
  }

  async fetchCMSHouseDetail(houseId: string, filter: IFilter<IPropertyWithRelations> = {}): Promise<void> {
    try {
      const houseDetail: IPropertyWithRelations = (await getCMSHouseDetail(houseId, filter)) as IPropertyWithRelations
      this.houseDetail = houseDetail
    } catch (error) {
      handleError(error as Error, 'stores/cms/cmsHouseStore.ts', 'fetchCMSHouseDetail')
    }
  }

  async fetchCMSHouseDetailByBuildiumPropertyId(
    buildiumPropertyId: number,
    filter: IFilter<IPropertyWithRelations>
  ): Promise<void> {
    try {
      const houseDetail: IPropertyWithRelations = (await getCMSHouseDetailByBuildiumPropertyId(
        buildiumPropertyId,
        filter
      )) as IPropertyWithRelations
      this.houseDetail = houseDetail
    } catch (error) {
      handleError(error as Error, 'stores/cms/cmsHouseStore.ts', 'fetchCMSHouseDetailByBuildiumPropertyId')
    }
  }

  async updateHouse(id: string, houseData: Partial<IProperty>): Promise<void> {
    await updateCMSHouse(id, houseData)
  }

  // *TODO: Intergrate new uploadOwnerLease file
  async postOwnerLeaseFile(id: string, file: File): Promise<string> {
    const result = await uploadOwnerLeaseFile(id, file)
    return result ?? ''
  }
}

export default HouseStore
