import get from 'lodash/get'
import { api, auth } from 'API'
import { PLATFORM } from 'API/constants'
import { handleError } from 'API/error'
import { IFaq } from 'interfaces/faq'

export async function getFaqs(): Promise<IFaq[]> {
  try {
    const response = await api.get('/staff/faqs', {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/cms/faq.ts', 'getFaqs')
    throw new Error(errorMessage)
  }
}

export async function updateFaq(faqs: IFaq[]): Promise<void> {
  try {
    await api.patch('/staff/faqs', faqs, {
      headers: auth(PLATFORM.CMS)
    })
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/cms/faq.ts', 'updateFaq')
    throw new Error(errorMessage)
  }
}

export async function deleteFaq(id: string): Promise<void> {
  try {
    await api.delete(`/staff/faqs/${id}`, {
      headers: auth(PLATFORM.CMS)
    })
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/cms/faq.ts', 'deleteFaq')
    throw new Error(errorMessage)
  }
}
