const ownerPortalRoutes = {
  ownerPortal: {
    value: '/owner-portal',
    login: {
      value: '/owner-portal/login'
    },
    forgotPassword: {
      value: '/owner-portal/forgot-password'
    },
    resetPassword: {
      value: (resetPasswordToken: string) => `/owner-portal/reset-password/${resetPasswordToken}`
    },
    accountSettings: {
      value: '/owner-portal/account-settings'
    },
    financial: {
      value: '/owner-portal/financial'
    },
    lease: {
      value: '/owner-portal/lease'
    },
    repair: {
      value: '/owner-portal/repair'
    }
  }
}

export default ownerPortalRoutes
