import { auth } from 'API'
import { PLATFORM } from 'API/constants'
import { handleError } from 'API/error'
import { IPolicyValue } from 'interfaces/policy'
import { api } from '..'

export async function getCMSPolicyValue(): Promise<IPolicyValue> {
  try {
    const response = await api.get(`/policies`, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/cms/policy.ts', 'getPolicyDefault')
    return {}
  }
}

export async function updateCMSPolicyValue(policyValueData: IPolicyValue): Promise<IPolicyValue> {
  try {
    const response = await api.post(`/staff/policies/upsert`, policyValueData, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/cms/policy.ts', 'updateCMSPolicyValue')
    return {}
  }
}
