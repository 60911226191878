import AuthStore from './authStore'
import CityStore from './cityStore'
import AccountStore from './cms/cmsAccountStore'
import AmenitiesPackageStore from './cms/cmsAmenitiesPackageStore'
import ApplicationStore from './cms/cmsApplicationStore'
import CMSBuildiumOwnerStore from './cms/cmsBuildiumOwnerStore'
import FaqStore from './cms/cmsFaqStore'
import FinancialUploaderStore from './cms/cmsFinancialUploaderStore'
import HouseDefaultValueStore from './cms/cmsHouseDefaultValueStore'
import HouseStore from './cms/cmsHouseStore'
import StaffLeaseStore from './cms/cmsLeaseStore'
import CMSListingStore from './cms/cmsListingStore'
import CMSMetroStore from './cms/cmsMetroStore'
import OwnerPaymentTrackerStore from './cms/cmsOwnerPaymentTrackerStore'
import CMSOwnerStore from './cms/cmsOwnerStore'
import PolicyStore from './cms/cmsPolicyStore'
import RoomDefaultValueStore from './cms/cmsRoomDefaultValueStore'
import CrawlerServiceStore from './cms/crawlerServiceStore'
import UnitStore from './cms/unitStore'
import HouseApplicationStore from './houseApplicationStore'
import ListingStore from './listingStore'
import MetroStore from './metroStore'
import MyPaymentMethodStore from './myPaymentMethodStore'
import FinancialStore from './ownerPortal/ownerFinancialStore'
import LeaseStore from './ownerPortal/ownerLeaseStore'
import ownerRepairStore from './ownerPortal/ownerRepairStore'
import OwnerStore from './ownerPortal/ownerStore'
import SpinnerStore from './spinnerStore'
import UserBackgroundInformationStore from './userBackgroundInformationStore'
export class RootStore {
  spinnerStore: SpinnerStore
  authStore: AuthStore
  // *INFO: Website - My Profile Stores
  myPaymentMethodStore: MyPaymentMethodStore
  // Website Stores
  listingStore: ListingStore
  unitStore: UnitStore
  cityStore: CityStore
  metroStore: MetroStore
  houseApplicationStore: HouseApplicationStore
  userBackgroundInformationStore: UserBackgroundInformationStore

  // Owner Portal Stores
  ownerRepairStore: ownerRepairStore
  ownerStore: OwnerStore
  ownerLeaseStore: LeaseStore
  ownerFinancialStore: FinancialStore

  // CMS Stores
  cmsFinancialUploaderStore: FinancialUploaderStore
  cmsAccountStore: AccountStore
  cmsBuildiumOwnerStore: CMSBuildiumOwnerStore
  cmsCrawlerServiceStore: CrawlerServiceStore
  cmsHouseStore: HouseStore
  cmsLeaseStore: StaffLeaseStore
  cmsListingStore: CMSListingStore
  cmsHouseDefaultValueStore: HouseDefaultValueStore
  cmsRoomDefaultValueStore: RoomDefaultValueStore
  cmsAmenitiesPackageStore: AmenitiesPackageStore
  cmsOwnerStore: CMSOwnerStore
  cmsOwnerPaymentTrackerStore: OwnerPaymentTrackerStore
  cmsMetroStore: CMSMetroStore
  cmsApplicationStore: ApplicationStore
  cmsPolicyStore: PolicyStore
  cmsFaqStore: FaqStore

  constructor() {
    this.spinnerStore = new SpinnerStore(this)
    this.authStore = new AuthStore(this)

    // *INFO: Website - My Profile Stores
    this.myPaymentMethodStore = new MyPaymentMethodStore(this)

    // Website Stores
    this.listingStore = new ListingStore(this)
    this.unitStore = new UnitStore(this)
    this.cityStore = new CityStore(this)
    this.metroStore = new MetroStore(this)
    this.houseApplicationStore = new HouseApplicationStore(this)
    this.userBackgroundInformationStore = new UserBackgroundInformationStore(this)

    // Owner Portal Stores
    this.ownerStore = new OwnerStore(this)
    this.ownerRepairStore = new ownerRepairStore(this)
    this.ownerLeaseStore = new LeaseStore(this)
    this.ownerFinancialStore = new FinancialStore(this)

    // CMS Stores
    this.cmsFinancialUploaderStore = new FinancialUploaderStore(this)
    this.cmsAccountStore = new AccountStore(this)
    this.cmsBuildiumOwnerStore = new CMSBuildiumOwnerStore(this)
    this.cmsCrawlerServiceStore = new CrawlerServiceStore(this)
    this.cmsHouseStore = new HouseStore(this)
    this.cmsLeaseStore = new StaffLeaseStore(this)
    this.cmsListingStore = new CMSListingStore(this)
    this.cmsHouseDefaultValueStore = new HouseDefaultValueStore(this)
    this.cmsRoomDefaultValueStore = new RoomDefaultValueStore(this)
    this.cmsAmenitiesPackageStore = new AmenitiesPackageStore(this)
    this.cmsOwnerStore = new CMSOwnerStore(this)
    this.cmsOwnerPaymentTrackerStore = new OwnerPaymentTrackerStore(this)
    this.cmsMetroStore = new CMSMetroStore(this)
    this.cmsApplicationStore = new ApplicationStore(this)
    this.cmsPolicyStore = new PolicyStore(this)
    this.cmsFaqStore = new FaqStore(this)
  }
}

export const rootStore = new RootStore()
