import { uniqBy } from 'lodash'
import { makeAutoObservable } from 'mobx'
import { RootStore } from 'stores'
import { PaginationList } from 'types'
import { AggregationPipeline } from 'types/mongo'
import { IFilter, Where } from 'types/query'
import { handleError } from 'API/error'
import {
  countHouses,
  deleteHouseById,
  getHouseDetail,
  getHouses,
  getHousesAggregate,
  countHousesAggregate
} from 'API/listing'
import { IListing, IListingWithRelations } from 'interfaces/listing'
import { IUnit } from 'interfaces/unit'
import { checkValidArray } from 'utils/common'

class ListingStore {
  rootStore: RootStore
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  houseList: IListing[] = []
  cmsHouseList: PaginationList<IListing> = {
    results: [],
    totalCount: 0
  }
  houseDetail: IListingWithRelations = {
    id: '',
    address: '',
    buildiumPropertyId: 0,
    lat: 0,
    lng: 0,
    bathrooms: 0,
    bedrooms: 0
  }
  countHouseList: number = 0
  roomsInHouse: IUnit[] = []
  selectedHouseId: string = ''

  async setHouseList(houseList: IListing[]) {
    this.houseList = houseList
  }

  setSelectedHouseId(id: string) {
    this.selectedHouseId = id
  }

  async fetchHouseList(filter: IFilter<IListing> = {}, isReset?: boolean) {
    try {
      const houseList: IListing[] = await getHouses(filter)
      if (isReset) {
        this.houseList = houseList
      }
      this.houseList = uniqBy([...this.houseList, ...houseList], 'id')
    } catch (error) {
      handleError(error as Error, 'stores/listingStore.ts', 'fetchHouseList')
    }
  }

  async fetchHouseListAggregate(pipeline: AggregationPipeline = [], isReset?: boolean) {
    try {
      const houseList: IListing[] = await getHousesAggregate(pipeline)
      if (isReset) {
        this.houseList = houseList
      }
      this.houseList = uniqBy([...this.houseList, ...houseList], 'id')
    } catch (error) {
      handleError(error as Error, 'stores/listingStore.ts', 'fetchHouseListAggregate')
    }
  }

  async getHouseListCountAggregate(pipeline: AggregationPipeline = []) {
    try {
      const houseLength: number = await countHousesAggregate(pipeline)
      this.countHouseList = houseLength
    } catch (error) {
      handleError(error as Error, 'stores/listingStore.ts', 'getHouseListCountAggregate')
    }
  }

  async getHouseListCount(filter: Where<IListing> = {}) {
    try {
      const houseLength: number = await countHouses(filter)
      this.countHouseList = houseLength
    } catch (error) {
      handleError(error as Error, 'stores/listingStore.ts', 'getHouseListCount')
    }
  }

  async fetchHouseDetailAggregate(pipeline: AggregationPipeline = []): Promise<void> {
    try {
      const houseList = await getHousesAggregate(pipeline)
      this.houseDetail = checkValidArray(houseList) && houseList?.length > 0 ? houseList[0] : ({} as IListing)
    } catch (error) {
      handleError(error as Error, 'stores/listingStore.ts', 'fetchHouseDetailAggregate')
    }
  }

  async fetchHouseDetail(id: string, filter: IFilter<IListing> = {}) {
    try {
      this.houseDetail = await getHouseDetail(id, filter)
    } catch (error) {
      handleError(error as Error, 'stores/listingStore.ts', 'fetchHouseDetail')
    }
  }

  async deleteHouse(id: string) {
    try {
      const result = await deleteHouseById(id)
      return result
    } catch (error) {
      handleError(error as Error, 'stores/listingStore.ts', 'deleteHouse')
      return false
    }
  }
}

export default ListingStore
