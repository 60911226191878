import { AxiosResponse } from 'axios'
import get from 'lodash/get'
import { IFilter } from 'types/query'
import { api, auth } from 'API'
import { PLATFORM } from 'API/constants'
import { handleError } from 'API/error'
import { IBerbixTransactionResponse } from 'components/pages/DetailPage/components/ApplicationFormModal/constant'
import { IPropertyUser } from 'interfaces/applicant'
import { IHouseApplication, IHouseApplicationWithRelations } from 'interfaces/application'
import { IProperty } from 'interfaces/property'

const BASE_PATH = '/applicant'

export async function getContinueLinkToApplyingForApplicant(
  id: string,
  filter: IFilter<IHouseApplication> = {}
): Promise<string> {
  try {
    const response = await api.get(`${BASE_PATH}/continue-link-to-apply/${id}?filter=${JSON.stringify(filter)}`, {
      headers: auth(PLATFORM.WEBSITE)
    })
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/applicant', 'getContinueLinkToApplyingForApplicant')
    return ''
  }
}

export async function getUserApplications(): Promise<IHouseApplicationWithRelations[]> {
  try {
    const response = await api.get(`${BASE_PATH}/house-applications`, {
      headers: auth(PLATFORM.WEBSITE)
    })
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/applicant', 'getMedia')
    return []
  }
}

export async function createDriverLicenseVerification(customerUid: string): Promise<IBerbixTransactionResponse> {
  try {
    const url = `${BASE_PATH}/create-berbix-transaction`
    const response = await api.post(url, JSON.stringify(customerUid), {
      headers: { ...auth(PLATFORM.WEBSITE), 'Content-Type': 'application/json' }
    })
    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/applicant', 'createDriverLicenseVerification')
    throw new Error(errorMessage)
  }
}

export async function savePropertyUser(propertyUser: IPropertyUser): Promise<void> {
  try {
    const url = `${BASE_PATH}/save-property`
    const response = await api.post(url, JSON.stringify(propertyUser), {
      headers: { ...auth(PLATFORM.WEBSITE), 'Content-Type': 'application/json' }
    })
    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/applicant', 'savePropertyUser')
    throw new Error(errorMessage)
  }
}

export async function getSavedHouseList(): Promise<IProperty[]> {
  try {
    const response: AxiosResponse = await api.get(`${BASE_PATH}/properties`, {
      headers: auth(PLATFORM.WEBSITE)
    })
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/listing', 'getHouseListByFilter')
    return []
  }
}
