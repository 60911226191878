import omit from 'lodash/omit'
import { makeAutoObservable } from 'mobx'
import router from 'next/router'
import { RootStore } from 'stores'
import { PaginationList } from 'types'
import { IFilter } from 'types/query'
import {
  createCMSMetro,
  createMetroPricing,
  getCMSMetroList,
  getPricingTableSettings,
  updateCMSMetro,
  updateMetroPricing,
  updatePricingTableSettings
} from 'API/cms/metro'
import { handleError } from 'API/error'
import { getMetros } from 'API/metro'
import { generalSettingsRoute } from 'components/pages/CMS/constant'
import { IOption } from 'interfaces/common'
import { IMetro, IMetroPricing, IMetroWithRelations, IPricingTableSettings } from 'interfaces/metro'
import { checkValidArray, getValidArray, hasChanges } from 'utils/common'
class CMSMetroStore {
  rootStore: RootStore
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  cmsMetroList: PaginationList<IMetroWithRelations> = {
    results: [],
    totalCount: 0
  }
  cmsMetroDetail: IMetroWithRelations = {} as IMetroWithRelations
  previousSelectedCities: IOption[] = []
  previousAvailableCities: IOption[] = []
  metroWithPricingList: IMetroWithRelations[] = []
  pricingTableSettings: IPricingTableSettings = {} as IPricingTableSettings

  async fetchCMSMetroList(filter: IFilter<IMetroWithRelations> = {}): Promise<void> {
    try {
      const metroList: PaginationList<IMetroWithRelations> = await getCMSMetroList(filter)
      this.cmsMetroList = metroList
    } catch (error) {
      handleError(error as Error, 'stores/cms/cmsMetroStore.ts', 'fetchCMSMetroList')
    }
  }

  async fetchCMSMetroDetail(filter: IFilter<IMetroWithRelations> = {}, metroName?: string): Promise<void> {
    try {
      if (metroName) {
        const metroList: PaginationList<IMetroWithRelations> = await getCMSMetroList(filter)
        if (checkValidArray(metroList?.results)) {
          this.cmsMetroList = metroList
          this.cmsMetroDetail = metroList.results.filter((metro: IMetroWithRelations) => {
            return metro.name === metroName
          })[0]
        }
      } else {
        router.push(generalSettingsRoute.metropolitans)
      }
    } catch (error) {
      handleError(error as Error, 'stores/cms/cmsMetroStore.ts', 'fetchCMSmetroDetail')
    }
  }

  async updateMetro(id: string, metroData: Partial<IMetro>): Promise<void> {
    await updateCMSMetro(id, metroData)
  }
  async createMetro(metroData: IMetro): Promise<void> {
    await createCMSMetro(metroData)
  }

  async fetchMetroPricingsList(): Promise<void> {
    try {
      const filter: IFilter<IMetro> = {
        include: ['metroPricing']
      }
      this.metroWithPricingList = await getMetros(filter)
    } catch (error) {
      handleError(error as Error, 'stores/metroStore.ts', 'fetchMetroPricingsList')
    }
  }

  async fetchPricingTableSettings(): Promise<void> {
    try {
      const pricingTableSettingsList = await getPricingTableSettings()
      this.pricingTableSettings = pricingTableSettingsList?.[0]
    } catch (error) {
      handleError(error as Error, 'stores/metroStore.ts', 'fetchPricingTableSettings')
    }
  }

  async updateCMSMetroPricingList(metroPricingList: IMetroPricing[]): Promise<void> {
    try {
      let hasChange: boolean = false
      await Promise.all(
        getValidArray(metroPricingList).map(async (metroPricing: IMetroPricing, index: number) => {
          const currentMetroPricing: IMetroPricing | undefined = this.metroWithPricingList?.[index]?.metroPricing
          if (metroPricing?.id && currentMetroPricing && !!hasChanges(currentMetroPricing, metroPricing)) {
            await updateMetroPricing(metroPricing)
            hasChange = true
          } else if (!metroPricing?.id) {
            await createMetroPricing(omit(metroPricing, 'id'))
            hasChange = true
          }
        })
      )
      if (hasChange) {
        await this.fetchMetroPricingsList()
      }
    } catch (error) {
      handleError(error as Error, 'stores/metroStore.ts', 'updateMetroPricing')
    }
  }

  async updateCMSPricingTableSettings(pricingTableSettings: IPricingTableSettings): Promise<void> {
    try {
      if (!!hasChanges(this.pricingTableSettings, pricingTableSettings)) {
        await updatePricingTableSettings(pricingTableSettings)
        await this.fetchPricingTableSettings()
      }
    } catch (error) {
      handleError(error as Error, 'stores/metroStore.ts', 'updatePricingTableSettings')
    }
  }

  setPreviousCities(selectedCities: IOption[], availableCities: IOption[]): void {
    this.previousSelectedCities = [...selectedCities]
    this.previousAvailableCities = [...availableCities]
  }
}

export default CMSMetroStore
