import { PaymentMethod } from '@stripe/stripe-js'
import { makeAutoObservable } from 'mobx'
import { RootStore } from 'stores'
import { handleError } from 'API/error'
import { getMyCustomer, getPaymentMethodsOfUser } from 'API/stripe'
import { IStripeCustomer } from 'interfaces/stripe'

class MyPaymentMethodStore {
  rootStore: RootStore
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  paymentMethods: PaymentMethod[] = []
  customer: IStripeCustomer = {} as IStripeCustomer

  async fetchPaymentMethods(): Promise<void> {
    try {
      this.paymentMethods = await getPaymentMethodsOfUser()
    } catch (error) {
      handleError(error as Error, 'stores/myPaymentMethodStore.ts', 'fetchPaymentMethods')
    }
  }

  async fetchMyCustomer(): Promise<void> {
    try {
      this.customer = await getMyCustomer()
    } catch (error) {
      handleError(error as Error, 'stores/myPaymentMethodStore.ts', 'fetchMyCustomer')
    }
  }
}

export default MyPaymentMethodStore
