// This file configures the initialization of Sentry on the server.
// The config you add here will be used whenever the server handles a request.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import { Integrations } from '@sentry/tracing'
import { EnvironmentEnum } from 'enums/common'
let environment = process.env.NODE_ENV // *development | production
if ((process.env.FE_BASE_URL || '').includes('livehomeroom')) {
  // *if base url is *.livehomeroom then environment should be staging or production
  environment = String(process.env.FE_BASE_URL).includes(EnvironmentEnum.STAGING)
    ? EnvironmentEnum.STAGING
    : EnvironmentEnum.PRODUCTION
}
Sentry.init({
  dsn: process.env.SENTRY_DSN,
  beforeSend(event, hint) {
    if (!hint.originalException) {
      return event
    }
    if (String(event.message).startsWith('Non-Error exception captured') && hint.originalException.error) {
      Sentry.withScope((scope) => {
        scope.setExtra('nonErrorException', true)
        Sentry.captureException(hint.originalException.error)
      })
      return null
    }
    return event
  },
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.5,
  integrations: [new Integrations.BrowserTracing()],
  environment
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
