import axios from 'axios'
import { ECrawlerServiceStatus } from 'enums/crawling'
import { auth } from 'API'
import { PLATFORM } from 'API/constants'
import { handleError } from 'API/error'

export const api = axios.create({
  baseURL: process.env.CRAWLER_BASE_URL
})

export async function getCrawlerServiceStatus(): Promise<ECrawlerServiceStatus> {
  try {
    const response = await api.get(`/staff/crawling/status`, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/cms/crawling.ts', 'getCrawlerStatus')
    return ECrawlerServiceStatus.IDLE
  }
}

export function triggerCrawlerService(): void {
  try {
    api.put('/staff/crawling/trigger', undefined, {
      headers: auth(PLATFORM.CMS)
    })
  } catch (error) {
    handleError(error as Error, 'API/cms/crawling.ts', 'triggerCrawlerServices')
  }
}
