import dayjs from 'dayjs'
import { api, auth } from 'API'
import { PaginationList, PLATFORM } from 'API/constants'
import { handleError } from 'API/error'
import { IRepairGroup } from 'interfaces/repair'

export async function getRepairs(
  skip: number,
  limit: number,
  selectedDay?: Date
): Promise<PaginationList<IRepairGroup>> {
  try {
    const response = await api.get(
      `/owner/repairs?month=${dayjs(selectedDay).format('MMM YYYY')}&skip=${skip}&limit=${limit}`,
      {
        headers: auth(PLATFORM.OWNER)
      }
    )
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/ownerPortal/repair', 'getRepairs')
    return { results: [], totalCount: 0 }
  }
}
