import * as Sentry from '@sentry/react'
import { EnvironmentEnum } from 'enums/common'
import isEmpty from 'lodash/isEmpty'

export function handleError(
  error: Error,
  filePath: string,
  functionName: string,
  isOnlyLog: boolean = false,
  group: string = ''
) {
  const errorPath = `Error: ${filePath} -> ${functionName} -> error: ${error}`
  if (!isOnlyLog && process.env.NODE_ENV !== EnvironmentEnum.DEVELOPMENT && process.env.ENV !== EnvironmentEnum.LOCAL) {
    if (!isEmpty(group)) {
      Sentry.setTag('group', group)
    }
    Sentry.captureException({
      errorPath,
      error: JSON.stringify(error)
    })
  } else {
    console.error(errorPath, JSON.stringify(error))
  }
}
