import { api } from 'API'
import { IUserBackgroundInformation } from 'interfaces/userBackgroundInformation'
import { IFilter } from './../types/query'
import { handleError } from './error'

export async function getUserBackgroundInformation(
  filter: IFilter<IUserBackgroundInformation>,
  userId: string
): Promise<IUserBackgroundInformation> {
  try {
    const response = await api.get(`/users/${userId}/user-background-information?filter=${JSON.stringify(filter)}`)
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/userBackgroundInformation.ts', 'getUserBackgroundInformation')
    return {} as IUserBackgroundInformation
  }
}

export async function patchUserBackgroundInformation(
  userId: string,
  userBackgroundInformation: IUserBackgroundInformation
): Promise<IUserBackgroundInformation> {
  try {
    const response = await api.patch(`/users/${userId}/user-background-information/upsert`, userBackgroundInformation)
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/userBackgroundInformation.ts', 'patchUserBackgroundInformation')
    return {} as IUserBackgroundInformation
  }
}
