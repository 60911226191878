import get from 'lodash/get'
import { IFilter } from 'types/query'
import { api, auth } from 'API'
import { PaginationList, PLATFORM } from 'API/constants'
import { handleError } from 'API/error'
import { IFinancialFile } from 'interfaces/financialUploader'

export async function uploadFinancialFiles(file: File): Promise<IFinancialFile> {
  const body = new FormData()
  body.append('file', file, file.name)
  try {
    const response = await api.put(`/staff/financial-files?originalFileName=${file.name}`, body, {
      headers: { ...auth(PLATFORM.CMS), 'Content-Type': 'multipart/form-data' }
    })
    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/cms/financialUploader.ts', 'uploadFinancialFiles')
    throw new Error(errorMessage)
  }
}

export async function getFinancialFiles(filter: IFilter<IFinancialFile>): Promise<PaginationList<IFinancialFile>> {
  try {
    const response = await api.get(`/staff/financial-files?filter=${JSON.stringify(filter)}`, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/cms/financialUploader.ts', 'getFinancialFiles')
    return { results: [], totalCount: 0 }
  }
}

export async function removeFinancialFiles(id: string): Promise<void> {
  try {
    const response = await api.delete(`/staff/financial-files/${id}`, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/cms/financialUploader.ts', 'removeFinancialFiles')
    throw new Error(errorMessage)
  }
}
