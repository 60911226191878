import get from 'lodash/get'
import { IFilter } from 'types/query'
import { api, auth } from 'API'
import { PLATFORM } from 'API/constants'
import { handleError } from 'API/error'
import { IProperty } from 'interfaces/property'

export async function getOwnerProperties(filter: IFilter<IProperty>): Promise<IProperty[]> {
  try {
    const response = await api.get(`owner/users/me/owned-properties?filter=${JSON.stringify(filter)}`, {
      headers: auth(PLATFORM.OWNER)
    })
    return response?.data ?? []
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/ownerPortal/owner', 'getOwnerProperties')
    throw new Error(errorMessage)
  }
}
