import get from 'lodash/get'
import { IFilter } from 'types/query'
import { api, auth } from 'API'
import { PaginationList, PLATFORM } from 'API/constants'
import { handleError } from 'API/error'
import { IUser } from 'interfaces/user'

export async function getAccounts(filter: IFilter<IUser>): Promise<PaginationList<IUser>> {
  try {
    const response = await api.get(`/staff/users?filter=${JSON.stringify(filter)}`, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/cms/account.ts', 'getAccounts')
    return { results: [], totalCount: 0 }
  }
}

export async function getAccountDetail(id: string, filter: IFilter<IUser> = {}): Promise<IUser> {
  try {
    const response = await api.get(`/staff/users/${id}?filter=${JSON.stringify(filter)}`, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/cms/account.ts', 'getAccountDetail')
    return {}
  }
}

export async function countAccounts(where: Record<string, unknown> = {}): Promise<number> {
  try {
    const { data = 0 } = await api.get(`/staff/users/count?where=${JSON.stringify(where)}`, {
      headers: auth(PLATFORM.CMS)
    })
    return Number(get(data, 'count', 0))
  } catch (error) {
    handleError(error as Error, 'API/cms/account.ts', 'countAccounts')
    return 0
  }
}

export async function createAccount(userData: IUser): Promise<IUser> {
  try {
    const response = await api.post(`/staff/users`, userData, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/cms/account.ts', 'createAccount')
    return {}
  }
}

export async function updateAccount(id: string, userData: IUser): Promise<IUser> {
  try {
    const response = await api.patch(`/staff/users/${id}`, userData, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/cms/account.ts', 'updateAccount')
    return {}
  }
}

export async function deleteAccount(id: string): Promise<void> {
  try {
    const response = await api.delete(`/staff/users/${id}`, {
      headers: auth(PLATFORM.CMS)
    })
    return response.data
  } catch (error) {
    const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
    handleError(error as Error, 'API/cms/account.ts', 'deleteAccount')
    throw new Error(errorMessage)
  }
}
