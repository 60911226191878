import { ECrawlerServiceStatus } from 'enums/crawling'
import { makeAutoObservable } from 'mobx'
import { RootStore } from 'stores'
import { getCrawlerServiceStatus } from 'API/cms/crawling'

class CrawlerServiceStore {
  rootStore: unknown

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }
  status: ECrawlerServiceStatus = ECrawlerServiceStatus.UNKNOWN

  async getCrawlerStatus() {
    const status: ECrawlerServiceStatus = await getCrawlerServiceStatus()
    this.status = status
  }

  setRunning() {
    this.status = ECrawlerServiceStatus.RUNNING
  }
}

export default CrawlerServiceStore
