import dayjs from 'dayjs'
import { get } from 'lodash'
import { makeAutoObservable } from 'mobx'
import { RootStore } from 'stores'
import { IFilter } from 'types/query'
import { handleError } from 'API/error'
import { IRoomDefaultValue } from 'interfaces/roomDefaultValue'
import { getCMSRoomDefaultValue, upsertCMSRoomDefaultValue } from '../../API/cms/generalInformation'
class RoomDefaultValueStore {
  rootStore: RootStore
  cmsRoomDefaultValue: IRoomDefaultValue = {}
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  async fetchCMSRoomDefaultValue(filter: IFilter<IRoomDefaultValue> = {}): Promise<void> {
    try {
      const roomDefaultValue: IRoomDefaultValue[] = await getCMSRoomDefaultValue(filter)
      try {
        roomDefaultValue[0].availableStartDate = new Date(
          dayjs(roomDefaultValue[0].availableStartDate).format('YYYY/MM/DD')
        )
        roomDefaultValue[0].availableEndDate = new Date(
          dayjs(roomDefaultValue[0].availableEndDate).format('YYYY/MM/DD')
        )
        this.cmsRoomDefaultValue = roomDefaultValue[0]
      } catch (error) {
        const errorMessage: string = get(error, 'message', '')
        throw new Error(errorMessage)
      }
    } catch (error) {
      handleError(error as Error, 'stores/cms/cmsRoomDefaultValueStore.ts', 'fetchCMSRoomDefaultValue')
    }
  }

  async updateRoomDefaultValue(roomDefaultValueData: IRoomDefaultValue): Promise<void> {
    await upsertCMSRoomDefaultValue(roomDefaultValueData)
  }
}

export default RoomDefaultValueStore
